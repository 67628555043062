export default [{
  id: 10,
  parentId: 0,
  icon: "shouye",
  name: "首页总览",
  url: "/dashboard"
}, {
  id: 20,
  parentId: 0,
  icon: "xixiangkehu",
  name: "意向客户",
  url: "/orderMange"
}, {
  id: 21,
  parentId: 20,
  icon: "",
  name: "待办池",
  url: "/orderMange/order"
}, {
  id: 23,
  parentId: 20,
  icon: "",
  name: "回收池",
  url: "/orderMange/recovery"
}, {
  id: 30,
  parentId: 0,
  icon: "daiqian",
  name: "贷前管理",
  url: "/approvals"
}, {
  id: 31,
  parentId: 30,
  icon: "",
  name: "审批列表",
  url: "/approvals/index"
}, {
  id: 40,
  parentId: 0,
  icon: "daiqianguanli",
  name: "贷中管理",
  url: "/daizhongguanli"
}, {
  id: 41,
  parentId: 40,
  name: "进行中",
  url: "/daizhongguanli/orderList"
}, {
  id: 42,
  parentId: 40,
  name: "已完结",
  url: "/daizhongguanli/orderOver"
}, {
  id: 45,
  parentId: 0,
  name: "订单中心",
  url: "/order"
}, {
  id: 46,
  parentId: 45,
  name: "审批中",
  url: "/order/approvaling"
}, {
  id: 47,
  parentId: 45,
  name: "进行中",
  url: "/order/doing"
}, {
  id: 48,
  parentId: 45,
  name: "已完结",
  url: "/order/finished"
}, {
  id: 50,
  parentId: 0,
  icon: "kehu",
  name: "客户信息",
  url: "/kehuguanli"
}, {
  id: 51,
  parentId: 50,
  icon: "",
  name: "我的客户",
  url: "/kehuguanli/Customer"
}, {
  id: 52,
  parentId: 50,
  icon: "",
  name: "所有客户",
  url: "/kehuguanli/AllCustomer"
}, {
  id: 60,
  parentId: 0,
  icon: "dashuju",
  name: "大数据",
  url: "/BigData"
}, {
  id: 61,
  parentId: 60,
  icon: "",
  name: "风控查询",
  url: "/BigData/RiskQuery"
}, {
  id: 62,
  parentId: 60,
  icon: "",
  name: "房屋估值",
  url: "/BigData/HousingValuation"
}, {
  id: 70,
  parentId: 0,
  icon: "rizhi",
  name: "操作日志",
  url: "/log"
}, {
  id: 80,
  parentId: 0,
  icon: "tuiguang",
  name: "推广管理",
  url: "/Popularization"
}, {
  id: 81,
  parentId: 80,
  name: "我的推广",
  url: "/Popularization/Statistics"
}, {
  id: 82,
  parentId: 80,
  name: "机构推广",
  url: "/Popularization/mechanism"
}, {
  id: 83,
  parentId: 80,
  name: "推广设置",
  url: "/Popularization/settingMork"
}, // {
//   id: 73,
//   parentId: 80,
//   name: "员工推广",
//   url: "/Popularization/bank"
// },
{
  id: 90,
  parentId: 0,
  icon: "chanpin",
  name: "产品管理",
  url: "/Production"
}, {
  id: 91,
  parentId: 90,
  icon: "",
  name: "产品管理",
  url: "/Production/productManagement"
}, {
  id: 92,
  parentId: 90,
  icon: "",
  name: "产品设置",
  url: "/Production/productSettings"
}, {
  id: 93,
  parentId: 90,
  icon: "",
  name: "类型管理",
  url: "/Production/TypeManagement"
}, {
  id: 94,
  parentId: 90,
  icon: "",
  name: "字段设置",
  url: "/Production/fieldSetting"
}, {
  id: 95,
  parentId: 90,
  icon: "",
  name: "规则设置",
  url: "/Production/ruleList"
}, {
  id: 100,
  parentId: 0,
  icon: "liucheng",
  name: "流程设置",
  url: "/approval"
}, {
  id: 101,
  parentId: 100,
  icon: "",
  name: "审批设置",
  url: "/approval/all"
}, {
  id: 110,
  parentId: 0,
  icon: "xitong",
  name: "系统管理",
  url: "/config",
  isBreak: true
}, {
  id: 111,
  parentId: 110,
  icon: "",
  name: "用户管理",
  url: "/config/user",
  isBreak: true
}, {
  id: 112,
  parentId: 110,
  icon: "",
  name: "角色管理",
  url: "/config/role",
  isBreak: true
}, {
  id: 120,
  parentId: 0,
  name: "财务统计",
  url: "/FinanceStatistics"
}, {
  id: 121,
  parentId: 120,
  name: "每期还款",
  url: "/FinanceStatistics/statement"
}, {
  id: 122,
  parentId: 120,
  icon: "xitong",
  name: "报表统计",
  url: "/FinanceStatistics/terminal"
}, {
  id: 123,
  parentId: 120,
  icon: "",
  name: "财务对账",
  url: "/FinanceStatistics/reconciliation"
}, {
  id: 124,
  parentId: 0,
  icon: "",
  name: "贷后管理",
  url: "/management"
}, {
  id: 126,
  parentId: 124,
  icon: "",
  name: "贷后还款",
  url: "/management/lending"
}, {
  id: 125,
  parentId: 120,
  icon: "",
  name: "放款确认",
  url: "/management/confirmation"
}];