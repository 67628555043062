import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
import { mapState } from "vuex";
export default {
  components: {},
  props: {},
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState(["settings"])),
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};