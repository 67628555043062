//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    value: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      options: [{
        type: 0,
        title: "不去重"
      }, {
        type: 1,
        title: "同一审批人出现多次只保留第一个"
      }, {
        type: 2,
        title: "同一审批人连续出现多次时,自动去重"
      }]
    };
  }
};