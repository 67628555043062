import "core-js/modules/es.array.find";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import logo from "./components/logo";
import footer from "./components/footer";
import SubMenu from "./components/SubMenu";
import Item from "./components/item";

function buildKeys(str) {
  var paths = str.split("/");
  paths.splice(0, 1);
  var keys = [];
  var baseUrl = "";

  for (var i = 0; i < paths.length; i++) {
    baseUrl += "/" + paths[i];
    keys.push(baseUrl);
  }

  return keys;
}

export default {
  name: "Sidebar",
  components: {
    "xq-footer": footer,
    logo: logo,
    SubMenu: SubMenu,
    Item: Item
  },
  props: {},
  data: function data() {
    return {
      hasLogo: true,
      selectedKeys: [],
      openKeys: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["sidebar", "permissions_menu", "permissions_menu_tree", "current_tab"])), {}, {
    isCollapsed: function isCollapsed() {
      return !this.sidebar.opened;
    }
  }),
  watch: {
    current_tab: function current_tab(val) {
      if (val === "/dashboard") {
        this.selectedKeys = [];
        this.openKeys = [];
      } else {
        this.selectedKeys = [val];
      }
    },
    $route: function $route(to, from) {
      if (to.path === "/dashboard") {
        this.selectedKeys = [];
        this.openKeys = [];
      }
    }
  },
  created: function created() {
    this.initMenuKeys();
  },
  methods: {
    initMenuKeys: function initMenuKeys() {
      if (this.$route.path !== this.current_tab) {
        if (this.$route.matched.length > 0) {
          var fullPath = this.$route.matched[this.$route.matched.length - 1]["path"];
          this.selectedKeys = buildKeys(fullPath);
          this.openKeys = buildKeys(fullPath);
        } else {
          this.openKeys = [];
        }
      }
    },
    onSelect: function onSelect(val) {
      var res = this.permissions_menu.find(function (menu) {
        return menu.url === val.key;
      });

      if (res) {
        this.$store.commit("navtab/addTab", res);
        this.$store.commit("navtab/setCurrentTab", val.key);
      }
    }
  }
};