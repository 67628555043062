var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-set" },
    [
      _c(
        "a-form",
        [
          _c(
            "a-form-item",
            { attrs: { label: "名称" } },
            [
              _c("a-input", {
                attrs: {
                  maxLength: 50,
                  disabled: _vm.isDisabled,
                  value: _vm.value.typeName,
                  placeholder: "请输入名称"
                },
                on: {
                  change: function($event) {
                    return _vm.onValueChange($event, "typeName")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "审批分组" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    value: _vm.value.groupId,
                    placeholder: "请选择",
                    defaultValue: "请选择..."
                  },
                  on: {
                    change: function($event) {
                      return _vm.onValueChange($event, "groupId")
                    }
                  }
                },
                _vm._l(_vm.groupList, function(item, i) {
                  return _c(
                    "a-select-option",
                    { key: i, attrs: { value: item.id } },
                    [_vm._v(" " + _vm._s(item.groupName) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "审批说明" } },
            [
              _c("a-textarea", {
                attrs: {
                  value: _vm.value.typeDesc,
                  maxLength: 100,
                  placeholder: "请输入"
                },
                on: {
                  change: function($event) {
                    return _vm.onValueChange($event, "typeDesc")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }