var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            destroyOnClose: true,
            title: "发起人",
            placement: "right",
            width: 550,
            closable: false,
            visible: _vm.show
          },
          on: { close: _vm.onClose, ok: _vm.onOk }
        },
        [
          _c(
            "div",
            { staticClass: "draw-right" },
            [
              _c(
                "a-tabs",
                { attrs: { defaultActiveKey: "1" } },
                [
                  _c("a-tab-pane", { key: "1", attrs: { tab: "设置发起人" } }, [
                    _c(
                      "div",
                      { staticClass: "start-box" },
                      [
                        _vm.startOptions.props
                          ? _c("div", { staticClass: "chosed-user mg_b10" }, [
                              _vm._v(" " + _vm._s(_vm.startOptions.props) + " ")
                            ])
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", sise: "small" },
                            on: { click: _vm.addUser }
                          },
                          [_vm._v(" 添加/修改发起人 ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "表单操作权限" } },
                    [
                      _c(
                        "div",
                        { staticClass: "form-body" },
                        _vm._l(_vm.startOptions.formSetting, function(fs, idx) {
                          return _c(
                            "a-row",
                            {
                              key: idx,
                              staticClass: "header",
                              attrs: { type: "flex" }
                            },
                            [
                              _c("a-col", { staticClass: "header-left" }, [
                                _vm._v(
                                  _vm._s(
                                    fs.flowFormName ? fs.flowFormName : fs.label
                                  )
                                )
                              ]),
                              _c("a-col", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "a-radio-group",
                                      {
                                        model: {
                                          value: fs.type,
                                          callback: function($$v) {
                                            _vm.$set(fs, "type", $$v)
                                          },
                                          expression: "fs.type"
                                        }
                                      },
                                      [
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "edit" } },
                                          [_vm._v("可编辑")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "read" } },
                                          [_vm._v("只读")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "hide" } },
                                          [_vm._v("隐藏")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "draw-foot" },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "8px" },
                  on: { click: _vm.onClose }
                },
                [_vm._v("取消")]
              ),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ]
      ),
      _c("employee-select", {
        attrs: {
          show: _vm.visible,
          defaultSelectedKeys: _vm.defaultKeys,
          title: "选择发起人"
        },
        on: {
          "update:show": function($event) {
            _vm.visible = $event
          },
          change: _vm.onEmployeeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }