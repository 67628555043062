var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 670,
            title: _vm.title,
            visible: _vm.show,
            cancelText: "取消",
            okText: "确定"
          },
          on: { cancel: _vm.cancel, ok: _vm.ok }
        },
        [
          _c("div", { staticClass: "employee-selector-wrapper" }, [
            _c(
              "div",
              { staticClass: "employee-selector-block" },
              [
                _c("a-input-search", {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "选择" + _vm.title },
                  on: { search: _vm.searchPeople }
                }),
                _c(
                  "div",
                  { staticClass: "employee-selector ove" },
                  [
                    _c("tree-node", {
                      attrs: { treeData: _vm.roleList },
                      on: {
                        "update:treeData": function($event) {
                          _vm.roleList = $event
                        },
                        "update:tree-data": function($event) {
                          _vm.roleList = $event
                        },
                        change: _vm.onChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "employee-selector-block" }, [
              _c("div", { staticClass: "employee-selector-title" }, [
                _vm._v(
                  " 已经选择的" +
                    _vm._s(_vm.title) +
                    "(" +
                    _vm._s(_vm.selectedListTemp.length) +
                    ") "
                )
              ]),
              _c(
                "div",
                { staticClass: "employee-selector" },
                _vm._l(_vm.selectedListTemp, function(item, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "employee-selector-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "ove" },
                        [
                          _c("a-icon", {
                            staticStyle: { "margin-right": "6px" },
                            attrs: { type: "user" }
                          }),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ],
                        1
                      ),
                      _c("a-icon", {
                        attrs: { type: "close" },
                        on: {
                          click: function($event) {
                            return _vm.deleteItem(item)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }