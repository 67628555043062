import "core-js/modules/es.function.name";
import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
import zh_CN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import { Common } from "xqjr-plugin-common";
import { Console } from "xqjr-module-console";
import { mapState } from "vuex";
import { userInfoLoadReq } from "@/api/user";
var SWITCH_MODULE_CONSOLE = process.env.VUE_APP_SWITCH_MODULE_CONSOLE === "true";
var SWITCH_PLUGIN_COMMON = process.env.VUE_APP_SWITCH_PLUGIN_COMMON === "true";
export default {
  name: "App",
  components: {
    Common: Common,
    Console: Console
  },
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterAlive: true,
      zh_CN: zh_CN,
      SWITCH_MODULE_CONSOLE: SWITCH_MODULE_CONSOLE,
      SWITCH_PLUGIN_COMMON: SWITCH_PLUGIN_COMMON
    };
  },
  computed: _objectSpread({}, mapState(["app"])),
  watch: {},
  created: function created() {
    var _this = this;

    // 加载动态系统配置
    if (process.env.VUE_APP_DYNAMIC_CONFIG === "true") {
      this.$store.dispatch("settings/changeSetting");
    } // 用于标记请求状态，防止相同状态重复弹出提醒


    localStorage.removeItem("reqStatus");
    this.$router.beforeEach(function (to, from, next) {
      if (to.path === "/dashboard") {
        userInfoLoadReq().then(function (res) {
          _this.$store.getters.userInfo.name = res.data.name;
          _this.$store.getters.userInfo.avatar = res.data.avatar;
        });
      }

      next();
    }); // const userMsg = localStorage.getItem("userMsg")
    // !!userMsg && this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(userMsg)))
    // // 在页面刷新时将vuex里的信息保存到localStorage里
    // window.addEventListener("beforeunload", () => {
    //   localStorage.setItem("userMsg", JSON.stringify(this.$store.state));
    // });

    this.$store.dispatch("auth/checkToken").catch(function () {
      _this.$store.dispatch("auth/refreshToken").catch(function () {
        _this.$store.dispatch("auth/resetToken");
      });
    });
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};