import request from "@/utils/request";
export function provinceListReq() {
  return request({
    url: "/finance/api/v1/vqapi/provinceList",
    method: "GET"
  });
}
export function cityListReq(params) {
  return request({
    url: "/finance/api/v1/vqapi/cityList",
    method: "GET",
    params: params
  });
}
export function areaListReq(params) {
  return request({
    url: "/finance/api/v1/vqapi/areaList",
    method: "GET",
    params: params
  });
}
export function projectListReq(params) {
  return request({
    url: "/finance/api/v1/vqapi/projectList",
    method: "GET",
    params: params
  });
}
export function buildingListReq(params) {
  return request({
    url: "/finance/api/v1/vqapi/buildingList",
    method: "GET",
    params: params
  });
}
export function floorListReq(params) {
  return request({
    url: "/finance/api/v1/vqapi/floorList",
    method: "GET",
    params: params
  });
}
export function houseListReq(params) {
  return request({
    url: "/finance/api/v1/vqapi/houseList",
    method: "GET",
    params: params
  });
}
export function assetEnquiryReq(data) {
  return request({
    url: "/finance/api/v1/vqapi/assetEnquiry",
    method: "post",
    data: data
  });
} // 根据客户ID获取房屋估值列表

export function selectByCustomerId(customerId) {
  return request({
    url: "/finance/api/v1/housingValuations/selectByCustomerId/".concat(customerId),
    method: "GET"
  });
} // 根据客户ID获取房屋估值列表

export function retrieveReq() {
  return request({
    url: "/finance/api/v1/housingValuations/selectByCustomerId",
    method: "GET"
  });
}