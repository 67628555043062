//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "Page404",
  computed: {
    message: function message() {
      return "页面找不到了...";
    }
  }
};