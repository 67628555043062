//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { formatDate } from "@/utils/date";
import { updateOrderInfos, loanInstalments, loanInstalmentsList, loanInstalmentsEdit, affirmBill } from "./api";
var columns = [{
  title: "期数",
  dataIndex: "numbers",
  scopedSlots: {
    customRender: "numbers"
  }
}, {
  title: "还款日期",
  dataIndex: "endTime",
  scopedSlots: {
    customRender: "endTime"
  }
}, {
  title: "还款额",
  dataIndex: "repaymentAmountDouble",
  scopedSlots: {
    customRender: "repaymentAmountDouble"
  }
}, {
  title: "本金",
  dataIndex: "repaymentPrincipalDouble",
  scopedSlots: {
    customRender: "repaymentPrincipalDouble"
  }
}, {
  title: "利息",
  dataIndex: "repaymentInterestDouble",
  scopedSlots: {
    customRender: "repaymentInterestDouble"
  }
}, {
  title: "操作",
  dataIndex: "tableAction",
  scopedSlots: {
    customRender: "tableAction"
  }
}];
import moment from "moment";
import "moment/locale/zh-cn";
export default {
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "等额本金";
      } else if (val === 2) {
        return "等额本息";
      } else if (val === 3) {
        return "先息后本";
      } else {
        return "";
      }
    }
  },
  data: function data() {
    var _this = this;

    return {
      dueDate: [{
        value: 1,
        label: "1日"
      }, {
        value: 2,
        label: "2日"
      }, {
        value: 3,
        label: "3日"
      }, {
        value: 4,
        label: "4日"
      }, {
        value: 5,
        label: "5日"
      }, {
        value: 6,
        label: "6日"
      }, {
        value: 7,
        label: "7日"
      }, {
        value: 8,
        label: "8日"
      }, {
        value: 9,
        label: "9日"
      }, {
        value: 10,
        label: "10日"
      }, {
        value: 11,
        label: "11日"
      }, {
        value: 12,
        label: "12日"
      }, {
        value: 13,
        label: "13日"
      }, {
        value: 14,
        label: "14日"
      }, {
        value: 15,
        label: "15日"
      }, {
        value: 16,
        label: "16日"
      }, {
        value: 17,
        label: "17日"
      }, {
        value: 18,
        label: "18日"
      }, {
        value: 19,
        label: "19日"
      }, {
        value: 20,
        label: "20日"
      }, {
        value: 21,
        label: "21日"
      }, {
        value: 22,
        label: "22日"
      }, {
        value: 23,
        label: "23日"
      }, {
        value: 24,
        label: "24日"
      }, {
        value: 25,
        label: "25日"
      }],
      timeValue: "",
      visible: false,
      visibles: false,
      columns: columns,
      tilte: "",
      formList: {
        id: "",
        repaymentAmountDouble: "",
        penaltyAmountDouble: "",
        lateFeeAmountDouble: ""
      },
      form: {
        statusType: "",
        loanCompleteTime: "",
        repaymentTime: ""
      },
      dataList: [],
      loanList: {},
      ruleList: [],
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.loan();
        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.loan();
        }
      }
    };
  },
  created: function created() {
    var _this2 = this;

    updateOrderInfos(this.$route.params.orderId).then(function (res) {
      _this2.form.repaymentTime = res.data.dueDate;
      _this2.form.statusType = res.data.isCurrentPeriod ? "0" : "1";
      _this2.form.loanCompleteTime = moment(res.dataloanCompleteTime).format("YYYY-MM-DD");
      _this2.loanList = res.data;
    });
    this.loan();
  },
  methods: {
    // 拿到账单列表
    loan: function loan() {
      var _this3 = this;

      loanInstalmentsList({
        orderInfoId: this.$route.params.orderId,
        pageNo: this.paginationOpt.defaultCurrent,
        pageSize: this.paginationOpt.defaultPageSize
      }).then(function (res) {
        _this3.ruleList = res.data.entities;
        _this3.paginationOpt.total = res.data.entityCount;
      });
    },
    // 获取还款日期
    onChangeDate: function onChangeDate(value) {
      this.form.repaymentTime = value;
    },
    // 获取放款日期
    onChange1: function onChange1(date, dateString) {
      this.form.loanCompleteTime = dateString;
    },
    // 生成账单
    changeAmount: function changeAmount() {
      var _this4 = this;

      if (this.form.repaymentTime !== "" && this.form.loanCompleteTime !== "") {
        var obj = {
          orderInfoId: this.$route.params.orderId,
          repaymentTime: this.form.repaymentTime,
          loanDeadline: this.loanList.deadline,
          rateInterest: this.loanList.interestRate,
          productDeadlineUnit: 0,
          repaymentType: this.loanList.repaymentType,
          loanCompleteTime: this.form.loanCompleteTime,
          auditAmount: this.loanList.amount,
          statusType: this.form.statusType
        };
        loanInstalments(obj).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success("生成成功");

            _this4.loan();
          }
        });
      } else {
        this.$message.error("请选择还款日期和放款日期");
      }
    },
    // 编辑回显
    show: function show(record) {
      this.visible = true;
      this.formList.id = record.id;
      this.tilte = "\u7F16\u8F91\u7B2C".concat(record.numbers, "\u671F\u8D26\u5355");
      this.formList.repaymentAmountDouble = record.repaymentAmountDouble;
      this.formList.penaltyAmountDouble = record.penaltyAmountDouble;
      this.formList.lateFeeAmountDouble = record.lateFeeAmountDouble;
    },
    // 确认账单
    createForm: function createForm() {
      this.visibles = true;
    },
    handleCreate: function handleCreate() {
      var _this5 = this;

      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      var lendingTime = date.year + "-" + date.month + "-" + date.day;
      var list = {
        orderInfoId: this.$route.params.orderId,
        lendingTime: lendingTime
      };
      affirmBill(list).then(function (res) {
        _this5.$message.success("确认账单成功");

        _this5.$router.go(-1);

        _this5.visibles = false;
      });
    },
    // 编辑修改
    handleOk: function handleOk() {
      var _this6 = this;

      this.formList.orderInfoId = this.$route.params.orderId;
      loanInstalmentsEdit(this.formList).then(function (res) {
        if (res.code === 200) {
          _this6.$message.success("修改成功");

          _this6.visible = false;

          _this6.loan();
        }
      });
    },
    // 返回
    go: function go() {
      this.$router.go(-1);
    },
    // 重置
    resetting: function resetting() {
      this.form.loanCompleteTime = "";
      this.form.repaymentTime = "";
      this.form.statusType = "";
      this.timeValue = "";
      this.$message.success("重置成功");
    }
  }
};