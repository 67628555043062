import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.timers";
import _toConsumableArray from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import * as houseApi from "./api";
import { selectByCustomerId, retrieveReq } from "./api";
import RecordForm from "./Form.json";
import { CurdMixin } from "xqjr-plugin-common";
import { formatDate } from "./date";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  mixins: [mixin],
  props: {
    customId: {
      type: String,
      default: ""
    },
    orderInfoId: {
      type: String,
      default: ""
    },
    customerInfo: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      spinning: true,
      form: this.$form.createForm(this, {
        name: "form"
      }),
      revaluation: true,
      areaOptions: [],
      project: [],
      tableDataList: [],
      formItem: false,
      houseSelectDisableds: true,
      provinceId: null,
      cityId: null,
      areaId: null,
      confirmAlert: false,
      projectSelectDisabled: true,
      projectOptions: [],
      projectId: null,
      projects: "",
      area: [],
      loadHouse: [],
      houseSelectDisabled: true,
      houseOptions: [],
      buildingId: null,
      building: {},
      floor: null,
      house: {},
      buildArea: "",
      assetEnquityDisabled: true,
      result: null,
      areaText: "",
      houseText: "",
      housingValuationVO: {}
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {}
      };
    }
  },
  created: function created() {
    this.dataLists();
  },
  mounted: function mounted() {
    this.initProviceData();
  },
  methods: {
    statusDetail: function statusDetail(record) {
      this.confirmAlert = true;
      this.area = [];
      this.projects = "";
      this.loadHouse = [];
      this.projectSelectDisabled = true;
      this.houseSelectDisabled = true;
      this.houseSelectDisableds = true;
      this.form.resetFields();
    },
    confirmAlertNo: function confirmAlertNo() {
      this.confirmAlert = false;
    },
    confirmHandle: function confirmHandle() {
      var _this = this;

      this.spinning = false;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this.buildArea = values.buildArea;
          houseApi.assetEnquiryReq({
            cityId: _this.cityId,
            areaId: _this.areaId,
            projectId: _this.projectId,
            projectName: _this.project.label,
            buildingId: _this.buildingId,
            buildingName: _this.building["label"],
            floor: _this.floor,
            houseId: _this.house["value"],
            houseName: _this.house["label"],
            buildArea: values.buildArea,
            orderInfoId: _this.orderInfoId,
            areaText: _this.areaText
          }).then(function (res) {
            if (res.code === 200) {
              _this.dataLists();

              _this.$message.success("更新成功");

              _this.confirmAlert = false;
              _this.spinning = true;
            }
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    },
    // 获取列表
    dataLists: function dataLists() {
      var _this2 = this;

      selectByCustomerId(this.customId).then(function (res) {
        _this2.tableDataList = res.data;

        _this2.toggleSpinning(false);
      });
    },
    onProjectChange: _.throttle(function (value) {
      var _this3 = this;

      if (value === "") {
        this.loadHouse = [];
        this.form.resetFields();
        this.houseSelectDisabled = true;
        this.houseSelectDisableds = true;
        this.assetEnquityDisabled = true;
      }

      houseApi.projectListReq({
        areaId: this.areaId,
        cityId: this.cityId,
        key: value.replace(/\s+/g, "")
      }).then(function (res) {
        _this3.projectOptions = res.data.map(function (item) {
          return {
            value: item.projectId,
            label: item.projectName
          };
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }, 500),
    initProviceData: function initProviceData() {
      var _this4 = this;

      houseApi.provinceListReq().then(function (res) {
        _this4.areaOptions = res.data.map(function (item) {
          return {
            value: item.provinceId,
            label: item.provinceName,
            isLeaf: false,
            type: "province"
          };
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    loadAreaData: function loadAreaData(selectedOptions) {
      var _this5 = this;

      var targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      switch (targetOption.type) {
        case "province":
          houseApi.cityListReq({
            provinceId: targetOption.value
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.cityId,
                label: item.cityName,
                isLeaf: false,
                type: "city"
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this5.areaOptions = _toConsumableArray(_this5.areaOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        case "city":
          houseApi.areaListReq({
            cityId: targetOption.value
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.areaId,
                label: item.areaName,
                isLeaf: true,
                type: "area"
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this5.areaOptions = _toConsumableArray(_this5.areaOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        default:
          break;
      }
    },
    onAreaChange: function onAreaChange(value, selectedOptions) {
      if (value.length === 0) {
        this.projects = "";
        this.loadHouse = [];
        this.projectSelectDisabled = true;
        this.houseSelectDisabled = true;
        this.houseSelectDisableds = true;
        this.assetEnquityDisabled = true;
        this.form.resetFields();
      }

      if (value.length > 2) {
        this.provinceId = value[0];
        this.cityId = value[1];
        this.areaId = value[2];
        this.dataList = value;
        this.projectSelectDisabled = false;
        this.houseSelectDisabled = true;
        this.assetEnquityDisabled = true;
        var tempAreaText = "";

        for (var i = 0; i < selectedOptions.length; i++) {
          tempAreaText += selectedOptions[i]["label"];
        }

        this.areaText = tempAreaText;
      } else {
        this.projectSelectDisabled = true;
        this.houseSelectDisabled = true;
        this.assetEnquityDisabled = true;
      }
    },
    onProjectSelect: function onProjectSelect(value) {
      var _this6 = this;

      console.log(value, 444);
      this.projectId = value;
      this.project = this.projectOptions.find(function (item) {
        return item.value === value;
      });
      this.houseSelectDisabled = false;
      this.assetEnquityDisabled = true;
      houseApi.buildingListReq({
        cityId: this.cityId,
        projectId: this.projectId
      }).then(function (res) {
        _this6.houseOptions = res.data.map(function (item) {
          return {
            value: item.buildingId,
            label: item.buildingName,
            isLeaf: false,
            type: "build"
          };
        });
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    loadHouseData: function loadHouseData(selectedOptions) {
      var _this7 = this;

      var targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      switch (targetOption.type) {
        case "build":
          this.buildingId = targetOption.value;
          houseApi.floorListReq({
            cityId: this.cityId,
            buildingId: this.buildingId
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.floor,
                label: item.floor + "层",
                isLeaf: false,
                type: "floor"
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this7.houseOptions = _toConsumableArray(_this7.houseOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        case "floor":
          this.floor = targetOption.value;
          houseApi.houseListReq({
            cityId: this.cityId,
            buildingId: this.buildingId,
            floor: this.floor
          }).then(function (res) {
            var resArr = res.data.map(function (item) {
              return {
                value: item.houseId,
                label: item.houseName,
                isLeaf: true,
                type: "house",
                buildArea: item.buildArea,
                housePurposeType: item.housePurposeType
              };
            });
            targetOption.loading = false;
            targetOption.children = resArr;
            _this7.houseOptions = _toConsumableArray(_this7.houseOptions);
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
          break;

        default:
          break;
      }
    },
    onHouseChange: function onHouseChange(value, selectedOptions) {
      if (value.length === 0) {
        this.houseSelectDisableds = true;
        this.assetEnquityDisabled = true;
        this.form.resetFields();
      }

      if (value.length > 2) {
        this.houseSelectDisableds = false;
        this.building = selectedOptions[0];
        this.house = selectedOptions[2];
        var tempHouseText = "";

        for (var i = 0; i < selectedOptions.length; i++) {
          tempHouseText += selectedOptions[i]["label"];
        }

        this.houseText = tempHouseText;
      } else {
        this.assetEnquityDisabled = true;
      }
    },
    changeBuildArea: function changeBuildArea(e) {
      if (e.data !== "") {
        this.assetEnquityDisabled = false;
      }
    },
    assetEnquity: function assetEnquity() {
      var _this8 = this;

      this.spinning = false;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this8.assetEnquityDisabled = true;
          setTimeout(function () {
            _this8.assetEnquityDisabled = false;
          }, 4000);
          _this8.buildArea = values.buildArea;
          houseApi.assetEnquiryReq({
            cityId: _this8.cityId,
            areaId: _this8.areaId,
            projectId: _this8.projectId,
            projectName: _this8.project.label,
            buildingId: _this8.buildingId,
            buildingName: _this8.building["label"],
            floor: _this8.floor,
            houseId: _this8.house["value"],
            houseName: _this8.house["label"],
            buildArea: values.buildArea,
            orderInfoId: _this8.orderInfoId,
            areaText: _this8.areaText
          }).then(function (res) {
            if (res.code === 200) {
              _this8.spinning = true;

              _this8.dataLists();
            }
          }).catch(function (err) {
            console.log("debug log --> ", err);
          });
        }
      });
    }
  }
};