import _defineProperty from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/defineProperty";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { allGroupAndFlowByCompanyUser } from "@/api/index";
import icons from "@/utils/icon";
export default {
  name: "BaseSet",
  components: {},
  props: {
    value: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      groupList: [],
      // 审批分组列表
      typeList: [],
      // 审批类型列表
      showIcon: false,
      isDisabled: false,
      icons: icons
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.loadGroupList(); // this.loadTypeList()
  },
  mounted: function mounted() {},
  methods: {
    onValueChange: function onValueChange(e, key) {
      var val = "";

      switch (key) {
        case "typeName":
          val = e.target.value;
          break;

        case "groupId":
          val = e;
          break;

        case "type":
          val = e;
          this.onApprovalTypeChange(e);
          break;

        case "revokeType":
          val = e;
          break;

        case "isSysStart":
          val = e;
          break;

        case "icon":
          val = e;
          break;

        case "typeDesc":
          val = e.target.value;
          break;

        default:
          break;
      }

      var res = Object.assign({}, this.value, _defineProperty({}, key, val));
      this.$emit("input", res);
      this.$emit("change", res);
    },
    // 选择图标
    choseIcon: function choseIcon(icon) {
      this.onValueChange(icon, "icon");
      this.showIcon = false;
    },
    loadGroupList: function loadGroupList() {
      var _this = this;

      var companyId = localStorage.getItem("lesseeId");
      allGroupAndFlowByCompanyUser("".concat(companyId), "".concat(process.env.VUE_APP_SUBJECT_TYPE)).then(function (res) {
        _this.groupList = res.data;
      });
    } // loadTypeList() {
    //   loadApprovalTypeListReq().then(res => {
    //     this.typeList = res.data ? res.data : []
    //   }).catch(err => {
    //     console.log('debug log --> ', err)
    //   })
    // },
    // 将数据传给父组件
    // onApprovalTypeChange(flowType) {
    //   loadFormByTypeReq(flowType).then(({ data }) => {
    //     if (data && data.length) {
    //       data.map(v => (v.props = JSON.parse(v.props)))
    //       this.$emit('formTypeChange', data)
    //     }
    //   }).catch(err => {
    //     console.log('debug log --> ', err)
    //   })
    // }

  }
};