var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifier" },
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 550,
            title: "抄送人设置",
            placement: "right",
            closable: false,
            visible: _vm.show
          },
          on: { close: _vm.onClose }
        },
        [
          _c("p", [_vm._v("抄送人")]),
          _c(
            "a-button",
            {
              staticClass: "mg_b20",
              attrs: { type: "primary" },
              on: { click: _vm.addEmployee }
            },
            [_vm._v(" 添加成员 ")]
          ),
          _vm.choseEmployees.length > 0
            ? _c(
                "div",
                { staticClass: "chosed-user mg_b10" },
                _vm._l(_vm.choseEmployees, function(item, idx) {
                  return _c("a-tag", { key: idx }, [
                    _vm._v(" " + _vm._s(item.name) + " ")
                  ])
                }),
                1
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              staticClass: "mg_b20",
              attrs: { type: "primary" },
              on: { click: _vm.addRole }
            },
            [_vm._v(" 添加角色 ")]
          ),
          _vm.choseRoles.length > 0
            ? _c(
                "div",
                { staticClass: "chosed-user mg_b10" },
                _vm._l(_vm.choseRoles, function(item, idx) {
                  return _c("a-tag", { key: idx }, [
                    _vm._v(" " + _vm._s(item.name) + " ")
                  ])
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mg_t20" },
            [
              _c(
                "a-checkbox",
                {
                  model: {
                    value: _vm.options.isUserChose,
                    callback: function($$v) {
                      _vm.$set(_vm.options, "isUserChose", $$v)
                    },
                    expression: "options.isUserChose"
                  }
                },
                [_vm._v(" 允许发起人自选抄送人 ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "draw-foot" },
            [
              _c(
                "a-button",
                { style: { marginRight: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("employee-select", {
        attrs: {
          show: _vm.employeeVisible,
          defaultSelectedKeys: _vm.defaultEmployeeKeys,
          title: _vm.title
        },
        on: {
          "update:show": function($event) {
            _vm.employeeVisible = $event
          },
          change: _vm.onEmployeeChange
        }
      }),
      _c("role-select", {
        attrs: {
          show: _vm.roleVisible,
          defaultSelectedKeys: _vm.defaultRoleKeys
        },
        on: {
          "update:show": function($event) {
            _vm.roleVisible = $event
          },
          change: _vm.onRoleChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }