import request from "@/utils/request"; // 批量删除用户

export function batchDeleteUser(data) {
  return request({
    url: "/finance/api/v1/users/delete",
    method: "POST",
    data: data
  });
} // 批量离职用户

export function batchDimissionUser(data) {
  return request({
    url: "/finance/api/v1/users/dimission",
    method: "POST",
    data: data
  });
} // 员工分页

export function getUsersInpage(data) {
  return request({
    url: "/finance/api/v1/users/page",
    method: "get",
    params: data
  });
} // 新增用户信息

export function addUser(data) {
  return request({
    url: "/finance/api/v1/users/save",
    method: "POST",
    data: data
  });
} // 简单员工分页，只包含id和姓名

export function getUserSimplePage() {
  return request({
    url: "/finance/api/v1/users/simplePage",
    method: "get"
  });
} // 管理员修改用户角色

export function updateRole(data) {
  return request({
    url: "/finance/api/v1/users/updateRole",
    method: "POST",
    data: data
  });
}
export function userInfoLoadReq() {
  return request({
    url: "/user/api/v1/users/me",
    method: "get"
  });
}