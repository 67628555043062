//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
import { detailReq } from "@/views/Customer/api";
export default {
  mixins: [FormMixin],
  data: function data() {
    return {
      editables: false
    };
  },
  mounted: function mounted() {},
  methods: {
    quxiao: function quxiao() {
      this.editables = false;
      this.$emit("cancel");
    },
    save: function save() {
      this.$emit("spouseList");
    },
    updateEditable: function updateEditable() {
      this.editables = false;
    },
    onValuesChangeCustom: function onValuesChangeCustom(values) {}
  }
};