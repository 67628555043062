import "core-js/modules/es.array.filter";
//
//
//
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {};
  },
  computed: {
    currSite: function currSite() {
      var siteList = this.$store.state.consolePermission.siteList;
      var currSite = siteList.filter(function (item) {
        return item.currSite;
      });

      try {
        return currSite[0]["id"];
      } catch (err) {
        return null;
      }
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    initFields: function initFields() {
      var _this = this;

      if (this.currSite) {
        this.$request({
          url: "/finance/api/v1/siteDefaultFieldAndProductType/".concat(this.currSite),
          method: "post"
        }).then(function (res) {
          console.log("debug log --> ", res);

          _this.$message.success("系统初始化成功！");
        }).catch(function (err) {
          console.log("debug log --> ", err);
        });
      } else {
        this.$message.error("siteId 不存在");
      }
    }
  }
};