import defaultSettings from "@/settings";
import request from "@/utils/request";

function getSetting() {
  return request({
    url: "/user/api/v1/systemConfigs/domain",
    method: "get"
  });
}

var copyright_title = defaultSettings.copyright_title,
    system_title = defaultSettings.system_title,
    need_subject = defaultSettings.need_subject,
    subject_title = defaultSettings.subject_title,
    need_project = defaultSettings.need_project,
    project_title = defaultSettings.project_title,
    fixed_header = defaultSettings.fixed_header,
    need_logo = defaultSettings.need_logo,
    need_tab = defaultSettings.need_tab,
    login_wx = defaultSettings.login_wx,
    use_site_name = defaultSettings.use_site_name;
var state = {
  copyright_title: copyright_title,
  system_title: system_title,
  need_subject: need_subject,
  subject_title: subject_title,
  need_project: need_project,
  project_title: project_title,
  fixed_header: fixed_header,
  need_logo: need_logo,
  need_tab: need_tab,
  login_wx: login_wx,
  use_site_name: use_site_name,
  deviceType: "web",
  login_code: true,
  login_pwd: true,
  wechatDomain: "",
  loaded: false
};
var mutations = {
  updateSetting: function updateSetting(state, data) {
    state.login_wx = data.wechatLoginEnable;
    state.copyright_title = data.copyRight;
    state.deviceType = data.deviceType ? data.deviceType : "web";
    state.login_code = data.verificationLoginEnable;
    state.wechatDomain = data.wechatDomain;
    state.loaded = true;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref) {
    var commit = _ref.commit;
    getSetting().then(function (res) {
      var data = res.data;

      if (data.webTitle) {
        document.title = data.webTitle;
      }

      if (data.webIcon) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = data.webIcon;
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      commit("updateSetting", data);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};