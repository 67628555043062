import request from "@/utils/request";
export function orderInfos(params) {
  return request({
    url: "/finance/api/v1/orderInfos/repayment",
    method: "GET",
    params: params
  });
}
export function repayment(orderInfoId) {
  return request({
    url: "/finance/api/v1/orderInfos/repayment/".concat(orderInfoId),
    method: "GET"
  });
}
export function finance(orderInfoId) {
  return request({
    url: "/finance/api/v1/loanInstalments/finance/".concat(orderInfoId),
    method: "GET"
  });
}
export function loanInstalmentsLogs(params) {
  return request({
    url: "/finance/api/v1/loanInstalmentsLogs",
    method: "GET",
    params: params
  });
}
export function financeEdit(data) {
  return request({
    url: "/finance/api/v1/loanInstalments/finance",
    method: "PUT",
    data: data
  });
}
export function agencyBatch(data) {
  return request({
    url: "/finance/api/v1/orderInfos/batch/agency",
    method: "PUT",
    data: data
  });
}