import "/data/jenkins/workspace/prod-e-loan/node_modules/core-js/modules/es.array.iterator.js";
import "/data/jenkins/workspace/prod-e-loan/node_modules/core-js/modules/es.promise.js";
import "/data/jenkins/workspace/prod-e-loan/node_modules/core-js/modules/es.object.assign.js";
import "/data/jenkins/workspace/prod-e-loan/node_modules/core-js/modules/es.promise.finally.js";
import Vue from "vue";
import antdv, { Icon } from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
Vue.use(antdv);
import onceClick from "./directives/onceClick";
Vue.use(onceClick);
import XqModalFooter from "./components/XqModalFooter";
Vue.component("XqModalFooter", XqModalFooter);
import KFormDesign from "k-form-design-callcter";
import "k-form-design-callcter/lib/k-form-design.css";
Vue.use(KFormDesign);
import "xqjr-plugin-common/AutoGenerate/CustomFormDesign";
import "@/styles/index.scss";
import "xqjr-plugin-form/styles/index.scss";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
var XqIcon = Icon.createFromIconfontCN({
  scriptUrl: "font_1637251_09q7591skjpg.js"
});
Vue.component("xq-icon", XqIcon);
import VuePerfectScrollbar from "vue-perfect-scrollbar";
Vue.component("xq-scrollbar", VuePerfectScrollbar);
import avoidrepateclick from "@/utils/avoidRepeatClick";
Vue.use(avoidrepateclick);
import App from "./App.vue";
import router from "./router";
import VueClipboards from "vue-clipboard2";
Vue.use(VueClipboards);
import store from "./store";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
Viewer.setDefaults({
  // 工具栏按钮的控制
  toolbar: {
    zoomIn: 1,
    // 放大
    zoomOut: 1,
    // 缩小
    oneToOne: 1,
    // 100%大小
    reset: 1,
    // 还原
    prev: 1,
    // 上一张
    play: 1,
    // 全屏显示
    next: 1,
    // 下一张
    rotateLeft: 1,
    // 逆时针旋转
    rotateRight: 1,
    // 顺时针旋转
    flipHorizontal: 1,
    // 水平翻转
    flipVertical: 1 // 垂直翻转

  }
});
import { store as auth } from "xqjr-module-auth";
store.registerModule("auth", auth);
import { XqTabs } from "xqjr-plugin-common";
Vue.component("xq-tabs", XqTabs);
import "xqjr-plugin-common/styles/index.scss";
import { store as common } from "xqjr-plugin-common";
store.registerModule("common", common);
import consolePermission from "xqjr-module-console/store/permission";
store.registerModule("consolePermission", consolePermission);
import service from "@/utils/request.js";
Vue.prototype.$request = service;
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");