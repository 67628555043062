import request from "@/utils/request";
export function orderInfos(params) {
  return request({
    url: "/finance/api/v1/orderInfos/repayment",
    method: "GET",
    params: params
  });
}
export function repayment(orderInfoId) {
  return request({
    url: "/finance/api/v1/orderInfos/repayment/".concat(orderInfoId),
    method: "GET"
  });
}
export function finance(orderInfoId) {
  return request({
    url: "/finance/api/v1/loanInstalments/finance/".concat(orderInfoId),
    method: "GET"
  });
}
export function loanInstalmentsLogs(params) {
  return request({
    url: "/finance/api/v1/loanInstalmentsLogs",
    method: "GET",
    params: params
  });
}
export function financeEdit(data) {
  return request({
    url: "/finance/api/v1/loanInstalments/finance",
    method: "PUT",
    data: data
  });
} // 一键确认：确认选中订单当前期的账单全部还款正常

export function affirmBatch(data) {
  return request({
    url: "/finance/api/v1/orderInfos/batch/affirm",
    method: "PUT",
    data: data
  });
} // 财务复审通过

export function financePass(orderInfoId) {
  return request({
    url: "/finance/api/v1/loanInstalments/financePass",
    method: "PUT",
    data: {
      orderInfoId: orderInfoId
    }
  });
} // 财务复审驳回

export function financeReject(orderInfoId, rejectExplain) {
  return request({
    url: "/finance/api/v1/loanInstalments/financeReject",
    method: "PUT",
    data: {
      orderInfoId: orderInfoId,
      rejectExplain: rejectExplain
    }
  });
}