import "core-js/modules/web.timers";
export default {
  install: function install(Vue) {
    // 防止重复点击
    Vue.directive("avoidRepeatClick", {
      inserted: function inserted(el, binding) {
        el.addEventListener("click", function () {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(function () {
              el.disabled = false;
            }, binding.value || 1000);
          }
        });
      }
    });
  }
};