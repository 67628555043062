var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c("div", { staticClass: "herder" }, [
        _c(
          "span",
          { staticClass: "icon" },
          [_c("a-icon", { attrs: { type: "left" }, on: { click: _vm.go } })],
          1
        ),
        _c("span", { staticClass: "tilte" }, [_vm._v("放款详情")])
      ]),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "a-row",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "font-size": "20px",
                    color: "#000000",
                    "font-weight": "bold"
                  },
                  attrs: { span: 24 }
                },
                [
                  _vm._v(
                    " 客户姓名 : " +
                      _vm._s(
                        _vm.loanList.customerInfosVO
                          ? _vm.loanList.customerInfosVO.customerVO.name
                          : ""
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "25px", "font-size": "14px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 联系电话 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.phone
                        : ""
                    )
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 申请产品 : " + _vm._s(_vm.loanList.productName))
              ]),
              _c("a-col", { attrs: { span: 8 } }, [_vm._v(" 终审金额 : ")])
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "10px", "font-size": "14px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 身份证号 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.idCard
                        : ""
                    )
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 客户来源 : " +
                    _vm._s(_vm.loanList.sourceType === 1 ? "分销" : "直销")
                )
              ]),
              _c(
                "a-col",
                {
                  staticStyle: {
                    "font-size": "20px",
                    color: "#000000",
                    "font-weight": "bold"
                  },
                  attrs: { span: 8 }
                },
                [_vm._v(" " + _vm._s(_vm.loanList.amount) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav1" },
        [
          _vm._m(0),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 还款利率 : " + _vm._s(_vm.loanList.interestRate))
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 还款方式 : " +
                    _vm._s(_vm._f("filterType")(_vm.loanList.repaymentType)) +
                    " "
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 贷款总额 : " + _vm._s(_vm.loanList.amount))
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 选择产品 : " + _vm._s(_vm.loanList.productName))
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 还款期数 : " + _vm._s(_vm.loanList.deadline))
              ]),
              _c("a-col", { attrs: { span: 8 } })
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _vm._v(" 放款日期： "),
                  _c("a-date-picker", {
                    attrs: { format: "YYYY-MM-DD" },
                    on: { change: _vm.onChange1 },
                    model: {
                      value: _vm.form.loanCompleteTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "loanCompleteTime", $$v)
                      },
                      expression: "form.loanCompleteTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _vm._v(" 还款日: "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      on: { change: _vm.onChangeDate },
                      model: {
                        value: _vm.form.repaymentTime,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "repaymentTime", $$v)
                        },
                        expression: "form.repaymentTime"
                      }
                    },
                    _vm._l(_vm.dueDate, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _vm._v(" 是否当期： "),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.statusType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "statusType", $$v)
                        },
                        expression: "form.statusType"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "0" } }, [
                        _vm._v("是")
                      ]),
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v("否")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.changeAmount }
            },
            [_vm._v(" 生成账单 ")]
          ),
          _c("a-button", { on: { click: _vm.resetting } }, [_vm._v(" 重置 ")])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav1" },
        [
          _vm._m(1),
          _c("a-table", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.ruleList,
              rowKey: "id",
              pagination: _vm.paginationOpt
            },
            scopedSlots: _vm._u([
              {
                key: "endTime",
                fn: function(text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm._f("formatDate")(record.endTime)) + " "
                    )
                  ])
                }
              },
              {
                key: "tableAction",
                fn: function(text, record) {
                  return _c("span", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.show(record)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn", staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.createForm } },
            [_vm._v(" 确认账单 ")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.tilte, visible: _vm.visible },
          on: {
            ok: _vm.handleOk,
            cancel: function($event) {
              _vm.visible = false
            }
          }
        },
        [
          _c(
            "a-form-model",
            { attrs: { model: _vm.formList } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "应还金额" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formList.repaymentAmountDouble,
                      callback: function($$v) {
                        _vm.$set(_vm.formList, "repaymentAmountDouble", $$v)
                      },
                      expression: "formList.repaymentAmountDouble"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "应还滞纳金" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formList.lateFeeAmountDouble,
                      callback: function($$v) {
                        _vm.$set(_vm.formList, "lateFeeAmountDouble", $$v)
                      },
                      expression: "formList.lateFeeAmountDouble"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "应还违约金" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formList.penaltyAmountDouble,
                      callback: function($$v) {
                        _vm.$set(_vm.formList, "penaltyAmountDouble", $$v)
                      },
                      expression: "formList.penaltyAmountDouble"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "确认订单", visible: _vm.visibles },
          on: {
            ok: _vm.handleCreate,
            cancel: function($event) {
              _vm.visibles = false
            }
          }
        },
        [_c("p", [_vm._v("确定要确认该账单吗!")])]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v("贷款信息")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v("账单信息")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }