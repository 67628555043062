//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nodeWrap from "./nodeWrap.vue"; // import { getFlowDetailReq } from './api'

export default {
  name: "FlowSetting",
  components: {
    nodeWrap: nodeWrap
  },
  props: {
    value: {
      type: Object,
      default: function _default() {}
    },
    formlist: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // 放大系数
      nowVal: 100,
      nodeId: 0
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 获取流程进程
    // getDetail() {
    //   getFlowDetailReq(this.$route.query.id).then(({ data }) => {
    //     this.$emit("flowProcessChange", this.deep(data));
    //   });
    // },
    deep: function deep(data) {
      if (data.props) {
        if (typeof data.props === "string" && data.nodeType !== "start") {
          data.props = JSON.parse(data.props);
        }
      }

      if (data.childNode) {
        this.deep(data.childNode);
      }

      return data;
    },

    /* 放大缩小 */
    zoomSize: function zoomSize(type) {
      if (type === 1) {
        if (this.nowVal === 50) {
          return this.nowVal === 50;
        }

        return this.nowVal -= 10;
      } else {
        if (this.nowVal === 300) {
          return this.nowVal === 300;
        }

        return this.nowVal += 10;
      }
    }
  }
};