//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import sidebar from "./components/sidebar";
import navbar from "./components/navbar";
import AppMain from "./components/appmain";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: "Layout",
  components: {
    sidebar: sidebar,
    navbar: navbar,
    AppMain: AppMain
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {
      collapsed: false
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixed_header: function fixed_header() {
      return this.$store.state.settings.fixed_header;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};