var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-wrapper",
      class: { "has-logo": _vm.hasLogo, collapsed: _vm.isCollapsed }
    },
    [
      _vm.hasLogo
        ? _c("logo", { attrs: { collapsed: _vm.isCollapsed } })
        : _vm._e(),
      _c(
        "xq-scrollbar",
        { staticClass: "sidebar-scroll-wrapper" },
        [
          _c(
            "a-menu",
            {
              attrs: {
                openKeys: _vm.openKeys,
                inlineCollapsed: _vm.isCollapsed,
                mode: "inline"
              },
              on: {
                "update:openKeys": function($event) {
                  _vm.openKeys = $event
                },
                "update:open-keys": function($event) {
                  _vm.openKeys = $event
                },
                select: _vm.onSelect
              },
              model: {
                value: _vm.selectedKeys,
                callback: function($$v) {
                  _vm.selectedKeys = $$v
                },
                expression: "selectedKeys"
              }
            },
            [
              _vm._l(_vm.permissions_menu_tree, function(route) {
                return [
                  !route.children
                    ? [
                        _c(
                          "a-menu-item",
                          { key: route.url },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: route.url } },
                              [
                                _c("item", {
                                  attrs: {
                                    icon: route.icon,
                                    title: route.title
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _c("sub-menu", { key: route.url, attrs: { item: route } })
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c("xq-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }