import Cookies from "js-cookie";
var state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : true,
    withoutAnimation: false
  },
  device: "desktop",
  mainHeight: 300
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  OPEN_SIDEBAR: function OPEN_SIDEBAR(state, withoutAnimation) {
    Cookies.set("sidebarStatus", 1);
    state.sidebar.opened = true;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  updateMainHeight: function updateMainHeight(state, payload) {
    state.mainHeight = payload;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  openSideBar: function openSideBar(_ref4, _ref5) {
    var commit = _ref4.commit;
    var withoutAnimation = _ref5.withoutAnimation;
    commit("OPEN_SIDEBAR", withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref6, device) {
    var commit = _ref6.commit;
    commit("TOGGLE_DEVICE", device);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};