var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-button",
    { attrs: { type: "primary" }, on: { click: _vm.initFields } },
    [_vm._v("为生成的租户创建默认字段及产品类型管理数据")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }