import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapState } from "vuex";
export default {
  name: "AppMain",
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["tabs", "current_tab"])), mapState(["settings"])), {}, {
    key: function key() {
      return this.$route.path;
    },
    needPadding: function needPadding() {
      if (this.$route.path === "/dashboard") {
        return false;
      }

      return true;
    }
  }),
  watch: {
    current_tab: function current_tab(val) {
      if (val !== this.$route.path) {
        this.$router.push(val);
      }
    }
  },
  created: function created() {
    if (!this.tabs) {
      this.$store.commit("navtab/initTabs");
      location.reload();
    }
  },
  mounted: function mounted() {
    try {
      var height = document.getElementById("main-content").offsetHeight;
      this.$store.commit("app/updateMainHeight", height - 40);
    } catch (err) {
      console.log("debug log --> ", err);
    }
  },
  methods: {
    removeTab: function removeTab(key) {
      this.$store.commit("navtab/removeTab", key);
    },
    setCurrentTab: function setCurrentTab(key) {
      if (this.$route.path !== key) {
        this.$router.push(key);
        this.$store.commit("navtab/setCurrentTab", key);
      }
    },
    removeAllTab: function removeAllTab() {
      this.$store.commit("navtab/removeAllTab");
    }
  }
};