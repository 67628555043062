//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "AddCondition",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      checkedList: []
    };
  },
  methods: {
    handleOk: function handleOk() {
      this.$emit("change", {
        visible: false,
        options: this.options
      });
    },
    handleCancel: function handleCancel() {
      this.$emit("change", {
        visible: false
      });
    },
    onChange: function onChange($event, e, i) {
      e.checked = $event.target.checked;
    }
  }
};