import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import app from "./modules/app";
import navtab from "./modules/navtab";
import settings from "./modules/settings";
import project from "./modules/project";
import VuexPersistence from "vuex-persist"; // vuex 持久化存储

var vuexLocal = new VuexPersistence({
  storage: window.localStorage
});
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    navtab: navtab,
    settings: settings,
    project: project
  },
  getters: getters,
  plugins: [vuexLocal.plugin]
});
export default store;