import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "FormSet",
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      val: null,
      source: [],
      id: this.$route.query.id,
      sourceData: [],
      jsonData: {
        list: [],
        config: {
          layout: "horizontal",
          labelCol: {
            span: 4
          },
          wrapperCol: {
            span: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      }
    };
  },
  computed: {},
  watch: {
    value: function value(val) {
      this.buildForm();
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handleFormDesignSave: function handleFormDesignSave() {
      this.jsonData.list = this.$refs.kfb.data.list;
      this.transJsonToSource(this.jsonData);
      this.$emit("input", this.sourceData);
      this.$emit("change", this.sourceData);
    },
    buildForm: function buildForm() {
      this.jsonData.list = this.value.map(function (v) {
        v.props.id = v.id;
        v.props.flowFormName = v.label;
        v.props.flowId = v.id;
        return v.props;
      });
      this.$refs.kfb.handleSetData(this.jsonData);
    },
    transSourceToJson: function transSourceToJson() {
      var list = [];
      this.sourceData.map(function (item) {
        if (!!item.props) {
          list.push(item.props);
        }
      });
      this.jsonData.list = list;
      this.$refs.kfb.handleSetData(this.jsonData);
    },
    transJsonToSource: function transJsonToSource(val) {
      var list = val["list"];
      var newList = [];

      for (var i = 0; i < list.length; i++) {
        newList.push(this.buildItem(list[i], i));
      }

      this.sourceData = newList;
    },
    buildItem: function buildItem(item, idx) {
      return {
        flowId: this.id,
        code: item.model,
        id: item.id,
        print: false,
        label: item.label,
        props: item,
        sort: idx,
        widgetType: item.type,
        required: this.isRequired(item.rules)
      };
    },
    isRequired: function isRequired(rules) {
      if (rules && rules.length) {
        for (var i = 0; i < rules.length; i++) {
          if (Object.keys(rules[i]).includes("required") && rules[i]["required"]) {
            return true;
          }
        }
      }

      return false;
    }
  }
};