import "core-js/modules/es.array.find";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.splice";
var state = {
  value: [{
    title: "总揽",
    url: "/dashboard"
  }],
  current_tab: "/dashboard"
};
var mutations = {
  setCurrentTab: function setCurrentTab(state, key) {
    state.current_tab = key;
  },
  addTab: function addTab(state, payload) {
    var res = state.value.find(function (item) {
      return item.url === payload.url;
    });

    if (!res) {
      state.value.push(payload);
    }
  },
  removeTab: function removeTab(state, key) {
    var idx = state.value.findIndex(function (item) {
      return item.url === key;
    });

    if (idx > -1) {
      state.value.splice(idx, 1);
    }

    state.current_tab = state.value[state.value.length - 1]["url"];
  },
  initTabs: function initTabs(state) {
    state.value = [{
      title: "总揽",
      url: "/dashboard"
    }];
    state.current_tab = "/dashboard";
  },
  removeAllTab: function removeAllTab(state) {
    if (state.value.length > 1) {
      state.value.splice(1, state.value.length - 1);
      state.current_tab = state.value[state.value.length - 1]["url"];
    }
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};