//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { updateOrderInfos, loanInstalmentsList, loanInstalmentsEdit } from "./api";
var columns = [{
  title: "期数",
  dataIndex: "numbers",
  scopedSlots: {
    customRender: "numbers"
  }
}, {
  title: "应还日期",
  dataIndex: "endTime",
  scopedSlots: {
    customRender: "endTime"
  }
}, {
  title: "实还日期",
  dataIndex: "repaymentTime",
  scopedSlots: {
    customRender: "repaymentTime"
  }
}, {
  title: "应还款额",
  dataIndex: "repaymentAmountDouble",
  scopedSlots: {
    customRender: "repaymentAmountDouble"
  }
}, {
  title: "实还款额",
  dataIndex: "repaymentRealDouble",
  scopedSlots: {
    customRender: "repaymentRealDouble"
  }
}, {
  title: "应还滞纳金",
  dataIndex: "lateFeeAmountDouble",
  scopedSlots: {
    customRender: "lateFeeAmountDouble"
  }
}, {
  title: "实还滞纳金",
  dataIndex: "lateFeeAmountRealDouble",
  scopedSlots: {
    customRender: "lateFeeAmountRealDouble"
  }
}, {
  title: "应还违约金",
  dataIndex: "penaltyAmountDouble",
  scopedSlots: {
    customRender: "penaltyAmountDouble"
  }
}, {
  title: "实还违约金",
  dataIndex: "penaltyAmountRealDouble",
  scopedSlots: {
    customRender: "penaltyAmountRealDouble"
  }
}, {
  title: "操作",
  dataIndex: "tableAction",
  scopedSlots: {
    customRender: "tableAction"
  }
}];
export default {
  data: function data() {
    var _this = this;

    return {
      columns: columns,
      loanList: {},
      visible: false,
      tilte: "",
      formList: {
        id: "",
        repaymentAmountDouble: "",
        penaltyAmountDouble: "",
        lateFeeAmountDouble: ""
      },
      ruleList: [],
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.loan();
        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.loan();
        }
      }
    };
  },
  created: function created() {
    var _this2 = this;

    updateOrderInfos(this.$route.params.orderId).then(function (res) {
      _this2.loanList = res.data;
    });
    this.loan();
  },
  methods: {
    // 拿到账单列表
    loan: function loan() {
      var _this3 = this;

      loanInstalmentsList({
        orderInfoId: this.$route.params.orderId,
        pageNo: this.paginationOpt.defaultCurrent,
        pageSize: this.paginationOpt.defaultPageSize
      }).then(function (res) {
        _this3.ruleList = res.data.entities;
        _this3.paginationOpt.total = res.data.entityCount;
      });
    },
    // 编辑回显
    show: function show(record) {
      this.visible = true;
      this.formList.id = record.id;
      this.tilte = "\u7F16\u8F91\u7B2C".concat(record.numbers, "\u671F\u8D26\u5355");
      this.formList.repaymentAmountDouble = record.repaymentAmountDouble;
      this.formList.penaltyAmountDouble = record.penaltyAmountDouble;
      this.formList.lateFeeAmountDouble = record.lateFeeAmountDouble;
    },
    // 编辑修改
    handleOk: function handleOk() {
      var _this4 = this;

      this.formList.orderInfoId = this.$route.params.orderId;
      loanInstalmentsEdit(this.formList).then(function (res) {
        if (res.code === 200) {
          _this4.$message.success("修改成功");

          _this4.visible = false;

          _this4.loan();
        }
      });
    },
    // 返回
    go: function go() {
      this.$router.go(-1);
    }
  }
};