var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "approval" },
    [
      _c(
        "a-drawer",
        {
          attrs: {
            title: "审批人",
            destroyOnClose: true,
            width: 550,
            closable: false,
            visible: _vm.show
          },
          on: { close: _vm.onClose, ok: _vm.onOk }
        },
        [
          _vm.options
            ? _c(
                "a-tabs",
                { attrs: { defaultActiveKey: "1" } },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "审批人设置" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          staticStyle: { width: "100%" },
                          on: { change: _vm.onRadio },
                          model: {
                            value: _vm.roleType,
                            callback: function($$v) {
                              _vm.roleType = $$v
                            },
                            expression: "roleType"
                          }
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              staticClass: "radio-appro",
                              attrs: { value: "APPROVER_SELECT" }
                            },
                            [_vm._v(" 指定成员 ")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radio-appro",
                              attrs: { value: "APPROVER_ROLE" }
                            },
                            [_vm._v("角色")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radio-appro",
                              attrs: { value: "APPROVER_OPTIONAL" }
                            },
                            [_vm._v(" 发起人自选 ")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radio-appro",
                              attrs: { value: "APPROVER_ORIGINATOR" }
                            },
                            [_vm._v(" 发起人自己 ")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radio-appro",
                              attrs: { value: "APPROVER_COMPANY_ROLE" }
                            },
                            [_vm._v(" 公司管理人员 ")]
                          )
                        ],
                        1
                      ),
                      _vm.roleType == "APPROVER_SELECT"
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.addEmployees }
                              },
                              [_vm._v(" 添加/修改成员 ")]
                            ),
                            _c("span", { staticClass: "sub_tit mg_l10" }, [
                              _vm._v("不能超过20人")
                            ]),
                            _vm.options.props
                              ? _c(
                                  "div",
                                  { staticClass: "mg_t10" },
                                  _vm._l(_vm.options.props.userList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("a-tag", { key: index }, [
                                      _vm._v(_vm._s(item.name))
                                    ])
                                  }),
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "sp mg_t10" },
                              [
                                _c("div", { staticClass: "mg_b12" }, [
                                  _vm._v("多人审批时采用的审批方式")
                                ]),
                                _c(
                                  "a-radio-group",
                                  {
                                    on: { change: _vm.appType },
                                    model: {
                                      value: _vm.options.activateType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.options,
                                          "activateType",
                                          $$v
                                        )
                                      },
                                      expression: "options.activateType"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mg_b12" },
                                      [
                                        _c("a-radio", { attrs: { value: 0 } }, [
                                          _vm._v("依次审批")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b12" },
                                      [
                                        _c("a-radio", { attrs: { value: 1 } }, [
                                          _vm._v("会签（须所有审批人同意）")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b12" },
                                      [
                                        _c("a-radio", { attrs: { value: 2 } }, [
                                          _vm._v(
                                            "或签（一名审批人同意或拒绝即可）"
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.roleType == "APPROVER_ROLE"
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.addRoles }
                              },
                              [_vm._v("添加角色")]
                            ),
                            _c(
                              "div",
                              { staticClass: "mg_t20 mg_b30" },
                              _vm._l(_vm.options.props.userList, function(
                                item,
                                index
                              ) {
                                return _c("a-tag", { key: index }, [
                                  _vm._v(_vm._s(item.name))
                                ])
                              }),
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mg_b20 b_bot" },
                              [
                                _c("div", { staticClass: "mg_b10" }, [
                                  _vm._v("多人审批时采用的审批方式")
                                ]),
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: _vm.options.activateType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.options,
                                          "activateType",
                                          $$v
                                        )
                                      },
                                      expression: "options.activateType"
                                    }
                                  },
                                  [
                                    false
                                      ? _c(
                                          "div",
                                          { staticClass: "mg_b10" },
                                          [
                                            _c(
                                              "a-radio",
                                              { attrs: { value: 0 } },
                                              [_vm._v("依次审批")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b10" },
                                      [
                                        _c("a-radio", { attrs: { value: 1 } }, [
                                          _vm._v("会签")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b10" },
                                      [
                                        _c("a-radio", { attrs: { value: 2 } }, [
                                          _vm._v("或签")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "b_bot" },
                              [
                                _c("div", { staticClass: "mg_b10" }, [
                                  _vm._v("审批人为空时")
                                ]),
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: _vm.options.noneActionerAction,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.options,
                                          "noneActionerAction",
                                          $$v
                                        )
                                      },
                                      expression: "options.noneActionerAction"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mg_b10" },
                                      [
                                        _c("a-radio", { attrs: { value: 0 } }, [
                                          _vm._v("自动通过")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b10" },
                                      [
                                        _c("a-radio", { attrs: { value: 1 } }, [
                                          _vm._v("自动转交管理员")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.roleType == "APPROVER_OPTIONAL"
                        ? [
                            _c(
                              "div",
                              { staticClass: "box1" },
                              [
                                _c(
                                  "a-select",
                                  {
                                    staticClass: "mg_b20",
                                    staticStyle: { width: "120px" },
                                    model: {
                                      value: _vm.options.props.isMulti,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.options.props,
                                          "isMulti",
                                          $$v
                                        )
                                      },
                                      expression: "options.props.isMulti"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: 0 } },
                                      [_vm._v("自选一个人")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: 1 } },
                                      [_vm._v("自选多个人")]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "mg_b20" }, [
                                  _vm._v("选择范围")
                                ]),
                                _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "120px" },
                                    model: {
                                      value: _vm.options.props.range,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.options.props,
                                          "range",
                                          $$v
                                        )
                                      },
                                      expression: "options.props.range"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "allUser" } },
                                      [_vm._v("全公司用户")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "roleUser" } },
                                      [_vm._v("公司管理")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "limitUser" } },
                                      [_vm._v("特定用户")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "allRole" } },
                                      [_vm._v("角色")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "limitRole" } },
                                      [_vm._v("特定角色")]
                                    )
                                  ],
                                  1
                                ),
                                [
                                  _vm.options.props.range == "limitUser"
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "mg_l10",
                                          attrs: { type: "primary" },
                                          on: { click: _vm.addEmployees }
                                        },
                                        [_vm._v("添加成员")]
                                      )
                                    : _vm._e(),
                                  _vm.options.props.range == "roleUser"
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "mg_l10",
                                          attrs: { type: "primary" },
                                          on: { click: _vm.addCompanyRoles }
                                        },
                                        [_vm._v("添加管理")]
                                      )
                                    : _vm._e(),
                                  _vm.options.props.range == "limitRole"
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "mg_l10",
                                          attrs: { type: "primary" },
                                          on: { click: _vm.addRoles }
                                        },
                                        [_vm._v("添加角色")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "pad_t10" },
                                    _vm._l(_vm.options.props.userList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("a-tag", { key: index }, [
                                        _vm._v(_vm._s(item.name))
                                      ])
                                    }),
                                    1
                                  )
                                ]
                              ],
                              2
                            ),
                            _vm.options.props.isMulti == 1
                              ? _c(
                                  "div",
                                  [
                                    _c("div", { staticClass: "mg_b20" }, [
                                      _vm._v("多人审批时才用的方式")
                                    ]),
                                    _c(
                                      "a-radio-group",
                                      {
                                        model: {
                                          value: _vm.options.activateType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.options,
                                              "activateType",
                                              $$v
                                            )
                                          },
                                          expression: "options.activateType"
                                        }
                                      },
                                      [
                                        false
                                          ? _c(
                                              "div",
                                              { staticClass: "mg_b10" },
                                              [
                                                _c(
                                                  "a-radio",
                                                  { attrs: { value: 0 } },
                                                  [_vm._v("依次审批")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "mg_b10" },
                                          [
                                            _c(
                                              "a-radio",
                                              { attrs: { value: 1 } },
                                              [
                                                _vm._v(
                                                  "会签（须所有审批人同意）"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("a-radio", { attrs: { value: 2 } }, [
                                          _vm._v(
                                            "或签（一名审批人同意或拒绝即可）"
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.roleType == "APPROVER_COMPANY_ROLE"
                        ? [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.addCompanyRoles }
                              },
                              [_vm._v(" 添加/修改公司管理人员 ")]
                            ),
                            _c("span", { staticClass: "sub_tit mg_l10" }, [
                              _vm._v("不能超过20人")
                            ]),
                            _vm.options.props
                              ? _c(
                                  "div",
                                  { staticClass: "mg_t10" },
                                  _vm._l(_vm.options.props.userList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("a-tag", { key: index }, [
                                      _vm._v(_vm._s(item.name))
                                    ])
                                  }),
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "sp mg_t10" },
                              [
                                _c("div", { staticClass: "mg_b12" }, [
                                  _vm._v("多人审批时采用的审批方式")
                                ]),
                                _c(
                                  "a-radio-group",
                                  {
                                    on: { change: _vm.appType },
                                    model: {
                                      value: _vm.options.activateType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.options,
                                          "activateType",
                                          $$v
                                        )
                                      },
                                      expression: "options.activateType"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mg_b12" },
                                      [
                                        _c("a-radio", { attrs: { value: 0 } }, [
                                          _vm._v("依次审批")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b12" },
                                      [
                                        _c("a-radio", { attrs: { value: 1 } }, [
                                          _vm._v("会签（须所有审批人同意）")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mg_b12" },
                                      [
                                        _c("a-radio", { attrs: { value: 2 } }, [
                                          _vm._v(
                                            "或签（一名审批人同意或拒绝即可）"
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "表单操作设置" } },
                    [
                      _c(
                        "div",
                        { staticClass: "form-body" },
                        _vm._l(_vm.options.formSetting, function(item, idx) {
                          return _c(
                            "a-row",
                            {
                              key: idx,
                              staticClass: "header",
                              attrs: { type: "flex" }
                            },
                            [
                              _c("a-col", { staticClass: "header-left" }, [
                                _vm._v(
                                  _vm._s(
                                    item.flowFormName
                                      ? item.flowFormName
                                      : item.label
                                  )
                                )
                              ]),
                              _c(
                                "a-col",
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          model: {
                                            value: item.type,
                                            callback: function($$v) {
                                              _vm.$set(item, "type", $$v)
                                            },
                                            expression: "item.type"
                                          }
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: "edit" } },
                                            [_vm._v("可编辑")]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: "read" } },
                                            [_vm._v("只读")]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: "hide" } },
                                            [_vm._v("隐藏")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "draw-foot" },
            [
              _c(
                "a-button",
                {
                  staticStyle: { marginRight: "8px" },
                  on: { click: _vm.onClose }
                },
                [_vm._v("取消")]
              ),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("employee-select", {
        attrs: {
          show: _vm.employeeVisible,
          defaultSelectedKeys: _vm.defaultEmployeeKeys,
          title: _vm.title
        },
        on: {
          "update:show": function($event) {
            _vm.employeeVisible = $event
          },
          change: _vm.onEmployeeChange
        }
      }),
      _c("role-select", {
        attrs: {
          show: _vm.roleVisible,
          defaultSelectedKeys: _vm.defaultRoleKeys
        },
        on: {
          "update:show": function($event) {
            _vm.roleVisible = $event
          },
          change: _vm.onRoleChange
        }
      }),
      _c("company-role-select", {
        attrs: {
          show: _vm.addCompanyRole,
          title: _vm.title,
          defaultSelectedKeys: _vm.defaultCompanyRoleKeys
        },
        on: {
          "update:show": function($event) {
            _vm.addCompanyRole = $event
          },
          change: _vm.onCompanyRoleChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }