import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.timers";
import "core-js/modules/web.url";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios"; // ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin

import { riskControls as retrieveReq, generatePreviewUrl } from "./api";
import { getBrPreLoanReport } from "@/api/client";
import RecordForm from "./Form.json";
import { CurdMixin } from "xqjr-plugin-common";
import { formatDate } from "@/utils/date";
import { detailReq } from "@/views/Customer/api"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});
columns.push({
  width: 120,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  mixins: [mixin],
  props: {
    orderInfoId: {
      type: String,
      default: ""
    },
    customId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    var _this = this;

    return {
      checkForm: this.$form.createForm(this),
      confirmAlert: false,
      confirmAlerts: false,
      spinning: true,
      tempRecord: null,
      isDisabled: false,
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 5,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["5", "10"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.retrieveRecord(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.retrieveRecord();
        }
      },
      infoAddress: []
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          orderInfoId: this.orderInfoId
        }
      };
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;

    detailReq(this.customId).then(function (res) {
      if (res.data) {
        _this2.tempRecord = res.data;

        if (_this2.tableData.length === 0) {
          _this2.$nextTick(function () {
            _this2.checkForm.setFieldsValue({
              name: _this2.tempRecord.customerVO.name,
              mobile: _this2.tempRecord.customerVO.phone,
              idCard: _this2.tempRecord.customerVO.idCard
            });
          });
        }
      }
    }).catch(function (err) {
      console.log("debug log --> ", err);
    });
  },
  methods: {
    statusDetail: function statusDetail() {
      this.confirmAlerts = true;
      this.checkForm.resetFields();
    },
    confirmHandle: function confirmHandle() {
      var _this3 = this;

      this.spinning = false;
      this.checkForm.validateFields(function (err, values) {
        if (!err) {
          values.orderId = _this3.orderInfoId;
          getBrPreLoanReport(values).then(function (res) {
            _this3.$message.success("更新成功");

            _this3.checkForm.resetFields();

            _this3.confirmAlerts = false;
            _this3.spinning = true;

            _this3.retrieveRecord();
          }).catch(function (error) {
            console.log("debug log -error-> ", error);
          });
        }
      });
    },
    // 立即查询
    check: function check(e) {
      var _this4 = this;

      e.preventDefault(e);
      this.spinning = false;
      this.checkForm.validateFields(function (err, values) {
        if (!err) {
          _this4.isDisabled = true;
          setTimeout(function () {
            _this4.isDisabled = false;
          }, 4000);
          values.orderId = _this4.orderInfoId;
          getBrPreLoanReport(values).then(function (res) {
            _this4.checkForm.resetFields();

            _this4.retrieveRecord();

            _this4.spinning = true;
          }).catch(function (error) {
            console.log("debug log -error-> ", error);
          });
        }
      });
    },
    // 下载
    downloadFile: function downloadFile(record) {
      var list = [];
      var exitList = [];
      list.push(record.downloadReportUrl1);

      if (record.downloadReportUrl2 != null) {
        exitList.push(record.downloadReportUrl2);
      }

      var urlList = [].concat(list, exitList);
      axios({
        url: "/file/api/v1/site/download",
        method: "post",
        data: {
          siteFileUrls: urlList
        },
        responseType: "blob"
      }).then(function (res) {
        var blob = new Blob([res.data], {
          type: "application/zip"
        });
        var link = document.createElement("a");
        link.download = "文件" + ".zip";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    },
    preview: function preview(record) {
      var _this5 = this;

      this.confirmAlert = true;
      var list = [];
      var dataList = [];
      list.push(record.downloadReportUrl1);

      if (record.downloadReportUrl2 != null) {
        dataList.push(record.downloadReportUrl2);
      }

      generatePreviewUrl({
        siteFileUrl: list
      }).then(function (res) {
        if (res.code === 200) {
          _this5.infoAddress.push(res.data);

          if (dataList.length !== 0) {
            generatePreviewUrl({
              siteFileUrl: dataList
            }).then(function (res) {
              _this5.infoAddress.push(res.data);
            });
          }
        }
      });
    }
  }
};