import "core-js/modules/web.timers";
var onceClick = {
  install: function install(Vue) {
    Vue.directive("onceClick", {
      inserted: function inserted(el) {
        el.onclick = function () {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(function () {
              el.disabled = false;
            }, 1000);
          }
        };
      }
    });
  }
};
export default onceClick;