import _objectSpread from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import request from "@/utils/request";
export function orderInfos(params) {
  return request({
    url: "/finance/api/v1/orderInfos/repayment",
    method: "GET",
    params: params
  });
}
export function updateOrderInfos(orderInfoId) {
  return request({
    url: "/finance/api/v1/orderInfos/repayment/".concat(orderInfoId),
    method: "GET"
  });
}
export function loanInstalmentsList(params) {
  return request({
    url: "/finance/api/v1/loanInstalments",
    method: "GET",
    params: _objectSpread(_objectSpread({}, params), {}, {
      "orders[0].desc": false,
      "orders[0].property": "numbers"
    })
  });
}
export function loanInstalmentsEdit(data) {
  return request({
    url: "/finance/api/v1/loanInstalments",
    method: "PUT",
    data: data
  });
}