var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrapper" }, [
    _c(
      "div",
      { attrs: { id: "app-main" } },
      [
        _vm.settings.need_tab && _vm.tabs.length > 0
          ? _c(
              "div",
              { staticClass: "main-tabs" },
              [
                _vm._l(_vm.tabs, function(tab, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: _vm.current_tab === tab.url },
                      on: {
                        click: function($event) {
                          return _vm.setCurrentTab(tab.url)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(tab.title) + " "),
                      index > 0
                        ? _c("a-icon", {
                            attrs: {
                              type: "close-circle",
                              size: "mini",
                              theme: "filled"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.removeTab(tab.url)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                _vm.tabs.length > 1
                  ? _c(
                      "span",
                      { staticClass: "menu" },
                      [
                        _c(
                          "a-dropdown",
                          { attrs: { trigger: ["click"] } },
                          [
                            _c("a-icon", { attrs: { type: "down" } }),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  { key: "0", on: { click: _vm.removeAllTab } },
                                  [_vm._v("删除全部")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._l(_vm.tabs, function(tab, index) {
          return [
            _vm.current_tab === tab.url
              ? _c(
                  "div",
                  {
                    key: index,
                    class: {
                      "main-content-wrapper": true,
                      "without-tab": !_vm.settings.need_tab
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "main-content",
                        style: { padding: _vm.needPadding ? "20px" : "0" },
                        attrs: { id: "main-content" }
                      },
                      [
                        _c(
                          "transition",
                          { attrs: { name: "fade-transform", mode: "out-in" } },
                          [_c("router-view", { key: _vm.key })],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }