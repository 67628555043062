//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { formatDate } from "@/utils/date";
import { repayment, finance, loanInstalmentsLogs, financeEdit } from "./api";
var columns = [{
  title: "序号",
  dataIndex: "numbers",
  scopedSlots: {
    customRender: "numbers"
  }
}, {
  title: "交易时间",
  dataIndex: "endTime",
  scopedSlots: {
    customRender: "endTime"
  }
}, {
  title: "还款金额",
  dataIndex: "repaymentAmountDouble",
  scopedSlots: {
    customRender: "repaymentAmountDouble"
  }
}];
export default {
  data: function data() {
    var _this = this;

    return {
      visibleAhead: false,
      columns: columns,
      ruleList: [],
      loanList: {},
      financeList: {},
      repaymentRealDouble: "",
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.list(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.list();
        }
      }
    };
  },
  created: function created() {
    var _this2 = this;

    repayment(this.$route.params.orderId).then(function (res) {
      _this2.loanList = res.data;
    });
    finance(this.$route.params.orderId).then(function (res) {
      _this2.financeList = res.data;
      _this2.repaymentRealDouble = res.data.repaymentRealDouble;
    });
    this.dataList();
  },
  methods: {
    dataList: function dataList() {
      loanInstalmentsLogs().then(function (res) {});
    },
    update: function update() {
      var _this3 = this;

      var obj = {
        id: this.financeList.id,
        orderInfoId: this.$route.params.orderId,
        numbers: this.financeList.numbers,
        actualAmount: this.repaymentRealDouble,
        everyMonthPenaltyAmountReal: 0
      };
      financeEdit(obj).then(function (res) {
        _this3.$message.success("保存成功");
      });
    },
    // 返回
    go: function go() {
      this.$router.go(-1);
    }
  }
};