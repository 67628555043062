import request from "@/utils/request"; // 根据订单ID获取风控查询列表

export function riskControls(params) {
  return request({
    url: "/finance/api/v1/riskControls",
    method: "GET",
    params: params
  });
} // 批量下载

export function generatePreviewUrl(data) {
  return request({
    url: "/file/api/v1/site/generatePreviewUrl",
    method: "POST",
    data: data
  });
}