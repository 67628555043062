import request from "@/utils/request"; // 分页查询

export function downloadReq(data) {
  return request({
    url: "/file/api/v1/site/download",
    method: "post",
    data: data
  });
} // 分页查询

export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/imageMaterials",
    method: "GET",
    params: params
  });
} // 新增

export function createReq(data) {
  return request({
    url: "/finance/api/v1/imageMaterials",
    method: "POST",
    data: data
  });
} // 修改

export function updateReq(data) {
  return request("/finance/api/v1/imageMaterials", {
    method: "PUT",
    data: data
  });
} // 根据id查询详情

export function detailReq(data) {
  return request({
    url: "/mfs/api/v1/imageMaterials/".concat(data),
    method: "GET"
  });
} // 删除

export function deleteReq(params) {
  return request({
    url: "/finance/api/v1/imageMaterials/".concat(params),
    method: "DELETE"
  });
} // 批量删除

export function deleteBatchReq(data) {
  return request({
    url: "/finance/api/v1/imageMaterials",
    method: "DELETE",
    data: data
  });
}