var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body" }, [
    _c("div", { staticClass: "herder" }, [
      _c(
        "span",
        { staticClass: "icon" },
        [_c("a-icon", { attrs: { type: "left" }, on: { click: _vm.go } })],
        1
      ),
      _c("span", { staticClass: "tilte" }, [_vm._v("查看详情")])
    ]),
    _c(
      "div",
      { staticClass: "nav" },
      [
        _c(
          "a-row",
          { staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "a-col",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#000000",
                  "font-weight": "bold"
                },
                attrs: { span: 22 }
              },
              [
                _vm._v(
                  " 客户姓名 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.name
                        : ""
                    ) +
                    " "
                )
              ]
            ),
            _c("a-col", { attrs: { span: 2 } })
          ],
          1
        ),
        _c(
          "a-row",
          { staticStyle: { "margin-top": "25px", "font-size": "14px" } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 身份证号 : " +
                  _vm._s(
                    _vm.loanList.customerInfosVO
                      ? _vm.loanList.customerInfosVO.customerVO.idCard
                      : ""
                  )
              )
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(" 本期剩余应还 : " + _vm._s(_vm.loanList.repayment))
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(" 本期剩余已还 : " + _vm._s(_vm.loanList.yetRepayment))
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "nav1" },
      [
        _vm._m(0),
        _c(
          "a-row",
          { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 交易日期 : " +
                  _vm._s(_vm._f("formatDate")(_vm.financeList.repaymentTime))
              )
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 应还本金 : " +
                  _vm._s(_vm.financeList.repaymentPrincipalDouble)
              )
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 应还利息 : " + _vm._s(_vm.financeList.repaymentInterestDouble)
              )
            ])
          ],
          1
        ),
        _c(
          "a-row",
          { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 应还违约金 : " + _vm._s(_vm.financeList.penaltyAmountDouble)
              )
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 应还滞纳金 : " + _vm._s(_vm.financeList.lateFeeAmountDouble)
              )
            ])
          ],
          1
        ),
        _c(
          "a-row",
          { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 应还总额 : " + _vm._s(_vm.financeList.repaymentAmountDouble)
              )
            ]),
            _c(
              "a-col",
              { attrs: { span: 8 } },
              [
                _vm._v(" 本期实还总额 : "),
                _c("a-input", {
                  staticStyle: { width: "200px", "margin-left": "5px" },
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: _vm.repaymentRealDouble,
                    callback: function($$v) {
                      _vm.repaymentRealDouble = $$v
                    },
                    expression: "repaymentRealDouble"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "a-button",
          {
            staticStyle: { "margin-right": "10px" },
            attrs: { type: "primary" },
            on: { click: _vm.update }
          },
          [_vm._v(" 保存 ")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "nav1" },
      [
        _vm._m(1),
        _c("a-table", {
          staticStyle: { "margin-top": "20px" },
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.ruleList,
            rowKey: "id",
            pagination: _vm.paginationOpt
          }
        }),
        _c(
          "a-modal",
          {
            attrs: {
              title: "提前还款",
              visible: _vm.visibleAhead,
              cancelText: "取消",
              okText: "保存"
            },
            on: {
              ok: function($event) {
                return _vm.update()
              },
              cancel: function($event) {
                _vm.visibleAhead = false
              }
            }
          },
          [
            _c(
              "a-form-model",
              { attrs: { labelCol: { span: 8 }, wrapperCol: { span: 12 } } },
              [
                _c("a-form-model-item", { attrs: { label: "交易日期" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatDate")(_vm.financeList.repaymentTime)
                      ) +
                      " "
                  )
                ]),
                _c("a-form-model-item", { attrs: { label: "本期应还本金" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.financeList.repaymentPrincipalDouble) + " "
                  )
                ]),
                _c("a-form-model-item", { attrs: { label: "本期应还利息" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.financeList.repaymentInterestDouble) + " "
                  )
                ]),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "本期应还违约金" } },
                  [
                    _c("a-input", {
                      attrs: { placeholder: "请输入本期应还违约金" },
                      model: {
                        value: _vm.financeList.lateFeeAmountDouble,
                        callback: function($$v) {
                          _vm.$set(_vm.financeList, "lateFeeAmountDouble", $$v)
                        },
                        expression: "financeList.lateFeeAmountDouble"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v(" 本期还款")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v(" 交易记录")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }