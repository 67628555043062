var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.treeData, function(item, index) {
      return _c("div", { key: index, staticClass: "mg_t10" }, [
        _c(
          "div",
          {
            staticClass: "tree-item",
            on: {
              click: function($event) {
                return _vm.chose(item, _vm.treeData)
              }
            }
          },
          [
            _c(
              "span",
              [
                _c("a-icon", { attrs: { type: "user" } }),
                _vm._v(" " + _vm._s(item.name) + " ")
              ],
              1
            ),
            item.isSelected
              ? _c("a-icon", { attrs: { type: "check" } })
              : _vm._e()
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }