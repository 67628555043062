import { render, staticRenderFns } from "./nodeWrap.vue?vue&type=template&id=732855e4&scoped=true&"
import script from "./nodeWrap.vue?vue&type=script&lang=js&"
export * from "./nodeWrap.vue?vue&type=script&lang=js&"
import style0 from "./nodeWrap.vue?vue&type=style&index=0&id=732855e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732855e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/prod-e-loan/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('732855e4')) {
      api.createRecord('732855e4', component.options)
    } else {
      api.reload('732855e4', component.options)
    }
    module.hot.accept("./nodeWrap.vue?vue&type=template&id=732855e4&scoped=true&", function () {
      api.rerender('732855e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/approval/nodeWrap.vue"
export default component.exports