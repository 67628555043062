var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        { attrs: { type: "default" }, on: { click: _vm.cancel } },
        [_vm._v(" 取消 ")]
      ),
      _c(
        "a-button",
        {
          directives: [{ name: "onceClick", rawName: "v-onceClick" }],
          attrs: { type: "primary" },
          on: { click: _vm.ok }
        },
        [_vm._v(" 确定 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }