var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c("div", { staticClass: "herder" }, [
        _c(
          "span",
          { staticClass: "icon" },
          [_c("a-icon", { attrs: { type: "left" }, on: { click: _vm.go } })],
          1
        ),
        _c("span", { staticClass: "tilte" }, [_vm._v("查看详情")])
      ]),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "a-row",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "font-size": "20px",
                    color: "#000000",
                    "font-weight": "bold"
                  },
                  attrs: { span: 22 }
                },
                [
                  _vm._v(
                    " 客户姓名 : " +
                      _vm._s(
                        _vm.loanList.customerInfosVO
                          ? _vm.loanList.customerInfosVO.customerVO.name
                          : ""
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.visible = true
                            }
                          }
                        },
                        [_vm._v("驳回")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定通过此条?",
                            okText: "确定",
                            cancelText: "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.onfinancePass(_vm.loanList.id)
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v("确认")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "25px", "font-size": "14px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 身份证号 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.idCard
                        : ""
                    )
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 本期剩余应还 : " + _vm._s(_vm.loanList.repayment))
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 本期剩余已还 : " + _vm._s(_vm.loanList.yetRepayment))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav1" },
        [
          _vm._m(0),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 交易日期 : " +
                    _vm._s(_vm._f("formatDate")(_vm.financeList.repaymentTime))
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 应还本金 : " +
                    _vm._s(_vm.financeList.repaymentPrincipalDouble)
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 应还利息 : " +
                    _vm._s(_vm.financeList.repaymentInterestDouble)
                )
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 应还违约金 : " + _vm._s(_vm.financeList.penaltyAmountDouble)
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 应还滞纳金 : " + _vm._s(_vm.financeList.lateFeeAmountDouble)
                )
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "20px", "margin-left": "15px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 应还总额 : " + _vm._s(_vm.financeList.repaymentAmountDouble)
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 本期实还总额 :" +
                    _vm._s(_vm.financeList.repaymentRealDouble) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "btn" }),
      _c(
        "div",
        { staticClass: "nav1" },
        [
          _vm._m(1),
          _c("a-table", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.ruleList,
              rowKey: "id",
              pagination: _vm.paginationOpt
            }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "驳回",
            visible: _vm.visible,
            confirmLoading: _vm.confirmLoading
          },
          on: {
            ok: function($event) {
              return _vm.handleOk(_vm.loanList.id)
            },
            cancel: _vm.handleCancel
          }
        },
        [
          _c("p", [_vm._v("驳回理由:")]),
          _c("a-textarea", {
            attrs: {
              placeholder: "请输入驳回理由最多50个字",
              rows: 4,
              allowClear: "",
              maxLength: 50
            },
            model: {
              value: _vm.ModalText,
              callback: function($$v) {
                _vm.ModalText = $$v
              },
              expression: "ModalText"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v(" 本期还款")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v(" 交易记录")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }