import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addNode from "./addNode";
import startDrawer from "./dialogs/start-drawer";
import approDrawer from "./dialogs/appro-drawer";
import notifierDrawer from "./dialogs/notifier-drawer";
import blockDrawer from "./dialogs/block-drawer";
export default {
  name: "NodeWrap",
  components: {
    addNode: addNode,
    startDrawer: startDrawer,
    approDrawer: approDrawer,
    notifierDrawer: notifierDrawer,
    blockDrawer: blockDrawer
  },
  inheritAttrs: false,
  props: {
    process: {
      type: Object,
      default: function _default() {}
    },
    formlist: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    nodeId: {
      type: [String, Number],
      default: ""
    }
  },
  data: function data() {
    return {
      isInput: false,
      showStart: false,
      // 发起人抽屉
      showAppro: false,
      // 审批人抽屉
      showNotif: false,
      // 抄送人抽屉
      showBlock: false,
      // 条件抽屉
      startOptions: {},
      // 发起人参数
      approOptions: {},
      // 审批人参数
      notifOptions: {},
      // 抄送人参数
      blockOptions: {} // 条件参数

    };
  },
  computed: {},
  watch: {
    process: function process(val) {
      this.initOptions();
    }
  },
  created: function created() {
    this.initOptions();
  },
  mounted: function mounted() {},
  methods: {
    changeName: function changeName() {
      this.isInput = false;
    },
    addNodeId: function addNodeId(i) {},
    onValueChange: function onValueChange(data) {
      var options = null;

      switch (data.nodeType) {
        case "start":
          options = {
            id: data.id,
            name: data.name,
            nodeType: data.nodeType,
            flowId: data.flowId,
            limitUser: data.limitUser,
            limitDepartment: data.limitDepartment,
            props: data.props,
            formSetting: data.formSetting
          };
          break;

        case "approver":
          options = {
            id: data.id,
            name: data.name,
            nodeType: data.nodeType,
            flowId: data.flowId,
            flowGroupId: data.flowGroupId,
            flowBlockId: data.flowBlockId,
            activateType: data.activateType,
            isLastNode: data.isLastNode,
            noneActionerAction: data.noneActionerAction,
            parentNodeType: data.parentNodeType,
            parentNodeId: data.parentNodeId,
            props: data.props,
            formSetting: data.formSetting
          };
          break;

        case "notifier":
          options = {
            id: data.id,
            name: data.name,
            nodeType: data.nodeType,
            flowId: data.flowId,
            flowGroupId: data.flowGroupId,
            flowBlockId: data.flowBlockId,
            isUserChose: data.isUserChose,
            isLastNode: data.isLastNode,
            userIds: data.userIds,
            roles: data.roles,
            supervisor: data.supervisor,
            parentNodeType: data.parentNodeType,
            parentNodeId: data.parentNodeId,
            props: data.props,
            formSetting: data.formSetting
          };
          break;

        case "block":
          options = {
            id: data.id,
            name: data.name,
            nodeType: data.nodeType,
            flowId: data.flowId,
            flowGroupId: data.flowGroupId,
            flowBlockId: data.flowBlockId,
            isUserChose: data.isUserChose,
            isLastNode: data.isLastNode,
            userIds: data.userIds,
            roles: data.roles,
            supervisor: data.supervisor,
            parentNodeType: data.parentNodeType,
            parentNodeId: data.parentNodeId,
            props: data.props,
            formSetting: data.formSetting,
            conditionNodes: data.conditionNodes
          };
          break;
      }

      if (this.nodeType === "block") {
        if (data.nodeType === "condition") {
          this.process.conditionNodes.forEach(function (item) {
            if (item.priorityLevel === data.priorityLevel) {
              item = data;
            }
          });
        }
      } else {
        this.$emit("update:process", Object.assign(this.process, options));
      }
    },
    initOptions: function initOptions() {
      switch (this.process.nodeType) {
        case "start":
          this.startOptions = {
            id: this.process.id,
            name: this.process.name,
            nodeType: this.process.nodeType,
            flowId: this.process.flowId,
            limitUser: this.process.limitUser,
            limitDepartment: this.process.limitDepartment,
            props: this.process.props,
            formSetting: this.process.formSetting
          };
          break;

        case "approver":
          this.approOptions = {
            id: this.process.id,
            name: this.process.name,
            nodeType: this.process.nodeType,
            flowId: this.process.flowId,
            flowGroupId: this.process.flowGroupId,
            flowBlockId: this.process.flowBlockId,
            activateType: this.process.activateType,
            isLastNode: this.process.isLastNode,
            noneActionerAction: this.process.noneActionerAction,
            parentNodeType: this.process.parentNodeType,
            parentNodeId: this.process.parentNodeId,
            props: this.process.props,
            formSetting: this.process.formSetting
          };
          break;

        case "notifier":
          this.notifOptions = {
            id: this.process.id,
            name: this.process.name,
            nodeType: this.process.nodeType,
            flowId: this.process.flowId,
            flowGroupId: this.process.flowGroupId,
            flowBlockId: this.process.flowBlockId,
            isUserChose: this.process.isUserChose,
            isLastNode: this.process.isLastNode,
            userIds: this.process.userIds,
            roles: this.process.roles,
            supervisor: this.process.supervisor,
            parentNodeType: this.process.parentNodeType,
            parentNodeId: this.process.parentNodeId,
            props: this.process.props,
            formSetting: this.process.formSetting
          };
          break;

        case "block":
          this.blockOptions = {
            id: this.process.id,
            name: this.process.name,
            nodeType: this.process.nodeType,
            flowId: this.process.flowId,
            flowGroupId: this.process.flowGroupId,
            flowBlockId: this.process.flowBlockId,
            isUserChose: this.process.isUserChose,
            isLastNode: this.process.isLastNode,
            userIds: this.process.userIds,
            roles: this.process.roles,
            supervisor: this.process.supervisor,
            parentNodeType: this.process.parentNodeType,
            parentNodeId: this.process.parentNodeId,
            props: this.process.props,
            formSetting: this.process.formSetting,
            conditionNodes: this.process.conditionNodes
          };
      }
    },
    delNode: function delNode() {
      // 删除节点
      this.$emit("update:process", this.process.childNode);
    },
    openConfigDrawer: function openConfigDrawer(param) {
      this.deep(param);

      switch (this.process.nodeType) {
        case "start":
          this.startOptions = param;
          this.showStart = true;
          break;

        case "approver":
          this.approOptions = param;
          this.showAppro = true;
          break;

        case "notifier":
          this.notifOptions = param;
          this.showNotif = true;
          break;

        case "block":
          this.blockOptions = param;
          this.showBlock = true;
          break;
      }
    },
    deep: function deep(data) {
      // 递归 权限管理
      if (data.formSetting && !data.formSetting.length && this.formlist) {
        this.formlist.map(function (v) {
          data.formSetting.push({
            formCode: v.code,
            flowFormName: v.label,
            sort: v.sort,
            type: data.nodeType === "start" ? "edit" : "read"
          });
        });
      }
    },
    addTerm: function addTerm() {
      // 添加条件节点
      var len = this.process.conditionNodes.length + 1;
      this.process.conditionNodes.push({
        name: "\u6761\u4EF6".concat(len),
        // 节点名称
        operationName: "\u8BF7\u9009\u62E9\u6761\u4EF6",
        // 条件节点解释
        operationCode: "",
        // 条件节点运算逻辑
        nodeType: "condition",
        priorityLevel: len,
        props: {
          conditions: []
        }
      });
      this.$emit("update:process", this.process);
    },
    delTerm: function delTerm(index) {
      // 删除条件节点
      this.process.conditionNodes.splice(index, 1);
      this.$emit("update:process", this.process);

      if (this.process.conditionNodes.length === 1) {
        /*
           if (this.process.childNode) {
             if (this.process.conditionNodes[0].childNode) {
               this.reData(this.process.conditionNodes[0].childNode, this.process.childNode)
             } else {
               this.process.conditionNodes[0].childNode = this.process.childNode
             }
           } */

        /* 条件为一个时把前面的数据保留出阿来 */
        this.$emit("update:process", this.process.conditionNodes[0].childNode);
        /* 条件为一个时条件里的数据全删除*/
        // this.$emit("change", this.process.childNode);
      }
    },
    reData: function reData(data, addData) {
      if (!data.childNode) {
        data.childNode = addData;
      } else {
        this.reData(data.childNode, addData);
      }
    },
    // 数组转字符串
    arrToStr: function arrToStr(arr) {
      if (arr.length) {
        return arr.map(function (item) {
          return item.name;
        }).toString();
      }
    },

    /* 编辑title */
    editortitle: function editortitle() {
      this.isInput = true;
    }
  }
};