var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-node-btn-box" }, [
    _c(
      "div",
      { staticClass: "add-node-btn" },
      [
        _c(
          "a-popover",
          {
            attrs: { placement: "rightTop", trigger: "click" },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("template", { slot: "content" }, [
              _c("div", { staticClass: "add-node-popover-body" }, [
                _c(
                  "a",
                  {
                    staticClass: "add-node-popover-item",
                    on: {
                      click: function($event) {
                        return _vm.addType("approver")
                      }
                    }
                  },
                  [
                    _c("a-button", {
                      staticClass: "add-node-popover-item-button",
                      attrs: { type: "primary", shape: "circle", icon: "user" }
                    }),
                    _c("p", [_vm._v("审批人")])
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    staticClass: "add-node-popover-item",
                    on: {
                      click: function($event) {
                        return _vm.addType("notifier")
                      }
                    }
                  },
                  [
                    _c("a-button", {
                      staticClass: "add-node-popover-item-button notifier",
                      attrs: {
                        type: "primary",
                        shape: "circle",
                        icon: "highlight"
                      }
                    }),
                    _c("p", [_vm._v("抄送人")])
                  ],
                  1
                )
              ])
            ]),
            _c("a-button", {
              attrs: { shape: "circle", type: "primary", icon: "plus" }
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }