var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body" }, [
    _c("div", { staticClass: "herder" }, [
      _c(
        "span",
        { staticClass: "icon" },
        [_c("a-icon", { attrs: { type: "left" }, on: { click: _vm.go } })],
        1
      ),
      _c("span", { staticClass: "tilte" }, [_vm._v("查看详情")])
    ]),
    _c(
      "div",
      { staticClass: "nav" },
      [
        _c(
          "a-row",
          { staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "a-col",
              {
                staticStyle: {
                  "font-size": "20px",
                  color: "#000000",
                  "font-weight": "bold"
                },
                attrs: { span: 22 }
              },
              [
                _vm._v(
                  " 客户姓名 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.name
                        : ""
                    ) +
                    " "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "a-row",
          { staticStyle: { "margin-top": "25px", "font-size": "14px" } },
          [
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(
                " 身份证号 : " +
                  _vm._s(
                    _vm.loanList.customerInfosVO
                      ? _vm.loanList.customerInfosVO.customerVO.idCard
                      : ""
                  )
              )
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(" 本期剩余应还 : " + _vm._s(_vm.loanList.repayment))
            ]),
            _c("a-col", { attrs: { span: 8 } }, [
              _vm._v(" 本期剩余已还 : " + _vm._s(_vm.loanList.yetRepayment))
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "nav1" },
      [
        _vm._m(0),
        _c("a-table", {
          staticStyle: { "margin-top": "20px" },
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.ruleList,
            rowKey: "id",
            pagination: _vm.paginationOpt
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "xian" }),
      _c(
        "span",
        {
          staticStyle: {
            margin: "1px 0 0 15px",
            "font-size": "16px",
            "font-weight": "bold"
          }
        },
        [_vm._v(" 交易记录")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }