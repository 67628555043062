//
//
//
//
//
//
//
//
//
//
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    ok: function ok(e) {
      e.preventDefault();
      this.$emit("ok");
    },
    cancel: function cancel(e) {
      e.preventDefault();
      this.$emit("cancel");
    }
  }
};