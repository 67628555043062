//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "TreeNode",
  props: {
    treeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    chose: function chose(item, data) {
      item.isSelected = !item.isSelected;
      this.$emit("change", item);
    }
  }
};