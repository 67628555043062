import "core-js/modules/es.array.concat";
import request from "@/utils/request"; // 分页查询项目

export function retrieveReq(params) {
  return request({
    url: "/generate/api/v1/projects",
    method: "GET",
    params: params
  });
} // 新增项目

export function createReq(data) {
  return request({
    url: "/generate/api/v1/projects",
    method: "POST",
    data: data
  });
} // 修改项目

export function updateReq(data) {
  return request("/generate/api/v1/projects", {
    method: "PUT",
    data: data
  });
} // 根据项目id查询项目详情

export function projectDetail(projectId) {
  return request({
    url: "/generate/api/v1/projects/".concat(projectId),
    method: "GET"
  });
} // 删除项目

export function deleteReq(projectId) {
  return request({
    url: "/generate/api/v1/projects/".concat(projectId),
    method: "DELETE"
  });
} // 删除项目

export function deleteBatchReq(data) {
  return request({
    url: "/generate/api/v1/projects",
    method: "DELETE",
    data: data
  });
} // 修改分组

export function approFlowGroupsUpdate(data) {
  return request("/approval/api/v1/approFlowGroups", {
    method: "PUT",
    data: data
  });
} // 新增分组

export function approFlowGroupsCreate(data) {
  return request({
    url: "/approval/api/v1/approFlowGroups",
    method: "POST",
    data: data
  });
} // 删除分组

export function approFlowGroupsDelete(id) {
  return request({
    url: "/approval/api/v1/approFlowGroups/".concat(id),
    method: "DELETE"
  });
} // 查询分组

export function allBySite(module_type) {
  return request({
    url: "/approval/api/v1/approFlowGroups/allBySite/".concat(module_type),
    method: "GET"
  });
} // 拿到所有成员

export function users() {
  return request({
    url: "/user/api/v1/users",
    method: "GET"
  });
} // 发布

export function publishApproval(data) {
  return request({
    url: "/approval/api/v1/approFlows/publishFlow",
    method: "post",
    data: data
  });
} // 获取全部列表

export function approFlows(params) {
  return request({
    url: "/approval/api/v1/approFlows/allBySite/".concat(process.env.VUE_APP_SUBJECT_TYPE),
    method: "GET",
    params: params
  });
} // 根据ID获取列表

export function allByGroup(group_id, params) {
  return request({
    url: "/approval/api/v1/approFlows/allByGroup/".concat(group_id),
    method: "GET",
    params: params
  });
} // 启用

export function reinstateOne(id) {
  return request({
    url: "/approval/api/v1/approFlows/valid/reinstateOne/".concat(id),
    method: "PUT"
  });
} // 停用

export function leaveOne(id) {
  return request({
    url: "/approval/api/v1/approFlows/valid/leaveOne/".concat(id),
    method: "PUT"
  });
} // 删除某项

export function approFlowsDelete(id) {
  return request({
    url: "/approval/api/v1/approFlows/".concat(id),
    method: "DELETE"
  });
} // 获取全部列表

export function allByCompany(company_id, module_type, params) {
  return request({
    url: "/approval/api/v1/approFlows/allByCompany/".concat(company_id, "/").concat(module_type),
    method: "GET",
    params: params
  });
} // 根据ID获取审批分组

export function allGroupAndFlowByCompanyUser(companyId, module_type) {
  return request({
    url: "/approval/api/v1/approFlowGroups/allGroupAndFlowByCompanyUser/".concat(companyId, "/").concat(module_type),
    method: "GET"
  });
} // 查询详情

export function getApprovalDetail(flowId) {
  return request({
    url: "/approval/api/v1/approFlows/allFolwInfo/".concat(flowId, "?moduleType=loan"),
    method: "get"
  });
}