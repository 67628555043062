import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
var FormMixin = XqFormMixin();
import { detailReq, fieldsListAll } from "@/views/Customer/api";
export default {
  mixins: [FormMixin],
  data: function data() {
    return {
      info: [],
      editables: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    fieldsListAll({
      enable: 1
    }).then(function (res) {
      res.data.map(function (item) {
        _this.info.push(item.fieldVO.englishName);
      });
    });
  },
  methods: {
    quxiao: function quxiao() {
      this.editables = false;
      this.$emit("cancel");
    },
    save: function save() {
      this.$emit("fatherMethod");
    },
    updateEditable: function updateEditable() {
      this.editables = false;
    },
    onValuesChangeCustom: function onValuesChangeCustom(values) {
      this.$emit("married", values.marriedStatus_customerVO_marriedStatus);
    }
  }
};