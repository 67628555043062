export default {
  fields: {
    fileType: {
      model: "fileType",
      type: "select",
      wrapper: {
        label: "资料类型"
      },
      customize: {
        placeholder: "请选择影像材料类型",
        options: [{
          value: 1,
          label: "身份证"
        }, {
          value: 2,
          label: "婚姻证明材料"
        }, {
          value: 3,
          label: "户口本"
        }, {
          value: 4,
          label: "房产证"
        }, {
          value: 5,
          label: "产调"
        }, {
          value: 6,
          label: "银行流水"
        }, {
          value: 7,
          label: "营业执照"
        }, {
          value: 8,
          label: "资金用途证明"
        }, {
          value: 9,
          label: "征信查询授权书"
        }, {
          value: 10,
          label: "工作证明"
        }, {
          value: 11,
          label: "配偶身份证"
        }, {
          value: 12,
          label: "配偶征信报告"
        }, {
          value: 13,
          label: "银行实地照片"
        }, {
          value: 14,
          label: "银行外访报告"
        }, {
          value: 15,
          label: "企业征信查询授权书"
        }, {
          value: 16,
          label: "对公账户流水"
        }, {
          value: 17,
          label: "公司章程"
        }, {
          value: 18,
          label: "营业执照"
        }, {
          value: 19,
          label: "验资报告"
        }, {
          value: 20,
          label: "财务报表"
        }, {
          value: 21,
          label: "经营场所使用证明"
        }, {
          value: 22,
          label: "资金用途证明"
        }, {
          value: 23,
          label: "其他"
        }]
      },
      extend: {
        dynamic: false,
        dynamicKey: ""
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    fileUrl: {
      model: "fileUrl",
      type: "upload",
      wrapper: {
        label: "上传资料"
      },
      customize: {
        action: "/file/api/v1/site/default/uploadImg",
        accept: ".jpg, .jpeg, .png, .bmp, .gif, .mp3, .wav, .wmv, .mp4, .mkv, .avi",
        listType: "picture-card",
        multiple: true
      },
      decorator: {
        initialValue: undefined,
        rules: [{
          required: true,
          message: "必填项"
        }]
      },
      extend: {
        size: 10,
        limit: 10
      }
    }
  },
  config: {
    form: {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      }
    },
    create: {
      fieldKeys: ["fileType", "fileUrl"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    update: {
      fieldKeys: ["fileType", "fileUrl"],
      customize: {
        labelCol: {
          span: 4
        },
        wrapperCol: {
          span: 20
        }
      }
    },
    search: {},
    table: {
      fields: [{
        model: "fileType",
        title: "资料类型",
        width: 200
      }, {
        model: "fileUrl",
        title: "资料",
        ellipsis: true
      }],
      customize: {
        rowKey: "id",
        pagination: {
          pageSize: 20
        }
      },
      extend: {
        batch: true,
        action: {
          width: 150
        }
      }
    }
  }
};