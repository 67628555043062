var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bodys" },
    [
      _c("div", { staticClass: "herder" }, [
        _c(
          "span",
          { staticClass: "icon" },
          [_c("a-icon", { attrs: { type: "left" }, on: { click: _vm.go } })],
          1
        ),
        _c("span", { staticClass: "tilte" }, [_vm._v("还款详情")])
      ]),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "a-row",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "font-size": "20px",
                    color: "#000000",
                    "font-weight": "bold"
                  },
                  attrs: { span: 24 }
                },
                [
                  _vm._v(
                    " 客户姓名 : " +
                      _vm._s(
                        _vm.loanList.customerInfosVO
                          ? _vm.loanList.customerInfosVO.customerVO.name
                          : ""
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "25px", "font-size": "14px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 联系电话 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.phone
                        : ""
                    )
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 身份证号 : " +
                    _vm._s(
                      _vm.loanList.customerInfosVO
                        ? _vm.loanList.customerInfosVO.customerVO.idCard
                        : ""
                    )
                )
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 终审金额 : " + _vm._s(_vm.loanList.amount))
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "10px", "font-size": "14px" } },
            [
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 剩余应还 : " + _vm._s(_vm.loanList.surplusRepayment))
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v(" 实际已还 : " + _vm._s(_vm.loanList.yetRepayment))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav1" },
        [
          _c("a-table", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.ruleList,
              rowKey: "id",
              pagination: _vm.paginationOpt
            },
            scopedSlots: _vm._u([
              {
                key: "endTime",
                fn: function(text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm._f("formatDate")(record.endTime)) + " "
                    )
                  ])
                }
              },
              {
                key: "repaymentTime",
                fn: function(text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatDate")(record.repaymentTime)) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "tableAction",
                fn: function(text, record) {
                  return _c("span", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.show(record)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.tilte, visible: _vm.visible },
          on: {
            ok: _vm.handleOk,
            cancel: function($event) {
              _vm.visible = false
            }
          }
        },
        [
          _c(
            "a-form-model",
            { attrs: { model: _vm.formList } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "应还金额" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formList.repaymentAmountDouble,
                      callback: function($$v) {
                        _vm.$set(_vm.formList, "repaymentAmountDouble", $$v)
                      },
                      expression: "formList.repaymentAmountDouble"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "应还滞纳金" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formList.lateFeeAmountDouble,
                      callback: function($$v) {
                        _vm.$set(_vm.formList, "lateFeeAmountDouble", $$v)
                      },
                      expression: "formList.lateFeeAmountDouble"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "应还违约金" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formList.penaltyAmountDouble,
                      callback: function($$v) {
                        _vm.$set(_vm.formList, "penaltyAmountDouble", $$v)
                      },
                      expression: "formList.penaltyAmountDouble"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }