var state = {
  companyId: null,
  companyName: null
};
var mutations = {
  saveCompany: function saveCompany(state, payload) {
    state.companyId = payload.companyId;
    state.companyName = payload.companyName;
  },
  clearCompany: function clearCompany(state) {
    state.companyId = null;
    state.companyName = null;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};