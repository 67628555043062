import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ReportEditable from "./reportEditable";
import FormJson from "./reportEditableForm";
import { updateReq, updateReqs, orderInfoReq, detailReq } from "@/views/Customer/api";
export default {
  name: "",
  components: {
    ReportEditable: ReportEditable
  },
  props: {
    customerInfo: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: function _default() {}
    },
    customId: {
      type: String,
      default: ""
    },
    orderInfoIds: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      FormJson: FormJson,
      editable: false,
      tempRecord: null,
      jsonData: null,
      dynamicData: {}
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.dataList();
  },
  mounted: function mounted() {},
  methods: {
    quxiao: function quxiao() {
      this.editable = false;
      this.dataList();
    },
    // 获取订单信息
    dataList: function dataList() {
      var _this = this;

      orderInfoReq(this.orderInfoIds).then(function (res) {
        if (res.code === 200) {
          _this.tempRecord = res.data;
        }
      });
      detailReq(this.customId).then(function (res) {
        if (res.code === 200) {
          _this.tempRecords = res.data.reportVO;
        }
      });
    },
    getData: function getData() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        try {
          _this2.$refs["kfb"].getData().then(function (values) {
            var columnKeys = Object.keys(_this2.FormJson);
            var tableKeys = columnKeys.filter(function (item) {
              return _this2.FormJson[item]["type"] === "table";
            });

            if (tableKeys.length > 0) {
              var valueKeys = Object.keys(values);

              var _loop = function _loop(i) {
                var tableKey = tableKeys[i];
                var groupKeys = [];
                var filteredKeys = valueKeys.filter(function (valueKey) {
                  return valueKey.match(tableKey);
                });
                var filteredValues = [];

                for (var j = 0; j < filteredKeys.length; j++) {
                  var filteredKey = filteredKeys[j];
                  var splitKeys = filteredKey.split("_".concat(tableKey, "_"));

                  if (!groupKeys.includes(splitKeys[1])) {
                    groupKeys.push(splitKeys[1]);
                  }

                  filteredValues.push({
                    key: filteredKey,
                    value: values[filteredKey],
                    valueKey: splitKeys[0],
                    groupKey: splitKeys[1]
                  });
                  delete values[filteredKey];
                } // values[tableKey] = makeList(filteredValues, groupKeys);

              };

              for (var i = 0; i < tableKeys.length; i++) {
                _loop(i);
              }
            }

            var form = {
              id: _this2.orderInfoIds,
              amount: values.amount,
              deadline: values.deadline,
              interestRate: values.interestRate,
              loanAmount: values.loanAmount,
              loanReason: values.loanReason,
              loanToDoDetail: values.loanToDoDetail,
              loanType: values.loanType,
              loanToDo: values.loanToDo,
              fundMeasure: values.fundMeasure,
              returnResource: values.returnResource,
              riskControlMethod: values.riskControlMethod,
              riskControlMethod2: values.riskControlMethod2
            };
            var reportAddDTO = {
              operateRisk: values.operateRisk,
              guaranteeRisk: values.guaranteeRisk,
              moralRisk: values.moralRisk,
              conclusion: values.conclusion,
              customerId: _this2.customId
            };
            updateReqs(form).then(function (res) {
              if (res.success === true) {
                _this2.$message.success("修改成功");

                _this2.update = false;
                _this2.editable = false;

                _this2.dataList();

                _this2.$emit("reportData", values);
              }
            }).catch(function (err) {
              _this2.$message.error(err.message);
            });
            updateReq({
              id: _this2.customId,
              reportAddDTO: reportAddDTO
            }).then(function (res) {
              if (res.success === true) {
                _this2.update = false;
                console.log("debug log --> ", res);
              }
            }).catch(function (err) {
              _this2.$message.error(err.message);

              console.log("debug log --> ", err);
            });
          });
        } catch (err) {
          reject(err);
        }
      });
    }
  }
};