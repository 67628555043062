import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.slice";
import defaultSettings from "@/settings";
var title = defaultSettings.system_title || "";
export var getPageTitle = function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, "\u2014\u2014").concat(title);
  }

  return "".concat(title);
};
export var dataLeftCompleting = function dataLeftCompleting(bits, identifier, value) {
  value = Array(bits + 1).join(identifier) + value;
  return value.slice(-bits);
};