var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("k-form-design", {
        ref: "kfb",
        attrs: {
          toolbars: ["preview", "reset"],
          fields: ["input", "textarea", "number", "select", "date", "time"],
          showBtnText: true,
          showHead: false,
          headHeight: 60,
          customModel: true
        },
        on: { save: _vm.handleFormDesignSave }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }