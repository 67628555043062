import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.some";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _toConsumableArray from "/data/jenkins/workspace/prod-e-loan/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FormJson from "./FormJson";
import { createReq, retrieveReq, updateReq, deleteReq } from "./api";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import axios from "axios";
import { getToken } from "xqjr-module-auth";
var curdMixin = new XqCurdMixin({
  createReq: createReq,
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  deleteReq: deleteReq,
  FormJson: FormJson
});
export default {
  name: "",
  mixins: [curdMixin],
  data: function data() {
    return {
      pageSize: 20,
      previewModalVisible: false,
      dataList: [],
      imgList: [],
      urlList: [],
      roolList: []
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          customerId: this.$route.params.id
        }
      };
    },
    createParams: function createParams() {
      return {
        customerId: this.$route.params.id
      };
    }
  },
  methods: {
    formatValues: function formatValues(values) {
      values["fileUrl"] = this.formatUploadValue(values["fileUrl"]);

      if (!values["fileUrl"]) {
        this.$message.error("未选择文件或文件上传失败");
        return false;
      }

      return values;
    },
    deleteList: function deleteList(item, index) {
      var _this2 = this;

      var _this = this;

      this.$confirm({
        title: "确认要删除这个文件？",
        okText: "确定",
        cancelText: "取消",
        onOk: function onOk() {
          var imgListTemp = _toConsumableArray(_this2.imgList);

          var dataListTemp = _toConsumableArray(_this2.dataList);

          var idx = imgListTemp.findIndex(function (li) {
            return li.pic === item.pic;
          });

          if (idx !== -1) {
            _this2.tempRecord = item.record;
            imgListTemp.splice(idx, 1);
            dataListTemp.splice(idx, 1);
            _this2.imgList = imgListTemp;
            _this2.dataList = dataListTemp;
            var fileUrl = imgListTemp.map(function (li) {
              return li.pic;
            }).join(",");

            _this2.updateRecord({
              fileUrl: fileUrl
            });
          }
        }
      });
    },
    editData: function editData(record) {
      var _this3 = this;

      this.previewModalVisible = true;
      this.dataList = [];
      this.imgList = [];
      var data = record.fileUrl;
      data = data.split(",");
      data.map(function (res) {
        var image = _this3.matchType(res);

        var index = res.lastIndexOf("/");
        var extension = res.substring(index + 1, res.length);

        switch (image) {
          case "image":
            _this3.dataList.push(res);

            _this3.imgList.push({
              pic: res,
              list: extension,
              record: record
            });

            break;

          case "other":
            _this3.dataList.push(res);

            _this3.imgList.push({
              pic: res,
              list: extension,
              record: record
            });

            break;

          case "word":
            _this3.dataList.push(require("../../assets/word.png"));

            _this3.imgList.push({
              pic: require("../../assets/word.png"),
              list: extension,
              record: record
            });

            break;

          case "excel":
            _this3.dataList.push(require("../../assets/excel.png"));

            _this3.imgList.push({
              pic: require("../../assets/excel.png"),
              list: extension,
              record: record
            });

        }
      });
    },
    matchType: function matchType(fileName) {
      // 后缀获取
      var suffix = ""; // 获取类型结果

      var result = "";

      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      } // fileName无后缀返回 false


      if (!suffix) {
        result = false;
        return result;
      } // 图片格式


      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"]; // 进行图片匹配

      result = imglist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "image";
        return result;
      } // 匹配txt


      var txtlist = ["txt"];
      result = txtlist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "txt";
        return result;
      } // 匹配 excel


      var excelist = ["xls", "xlsx"];
      result = excelist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "excel";
        return result;
      } // 匹配 word


      var wordlist = ["doc", "docx"];
      result = wordlist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "word";
        return result;
      } // 匹配 pdf


      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "pdf";
        return result;
      } // 匹配 ppt


      var pptlist = ["ppt"];
      result = pptlist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "ppt";
        return result;
      } // 匹配 视频


      var videolist = ["mp4", "m2v", "mkv"];
      result = videolist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "video";
        return result;
      } // 匹配 音频


      var radiolist = ["mp3", "wav", "wmv"];
      result = radiolist.some(function (item) {
        return item === suffix;
      });

      if (result) {
        result = "radio";
        return result;
      } // 其他 文件类型


      result = "other";
      return result;
    },
    batchDownload: function batchDownload() {
      var _this4 = this;

      var dataList = this.tableData.filter(function (res) {
        return _this4.selectedRowKeys.includes(res.id);
      });
      var list = dataList.map(function (item) {
        return "".concat(process.env.VUE_APP_DOMAIN).concat(item.fileUrl);
      });
      list = list.join(",");
      axios({
        url: "/file/api/v1/site/default/download",
        method: "post",
        data: {
          siteFileUrls: list.split(",")
        },
        headers: {
          token: getToken()
        },
        responseType: "blob"
      }).then(function (res) {
        var blob = new Blob([res.data], {
          type: "application/zip"
        });
        var link = document.createElement("a");
        link.download = "客户影像资料.zip";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        _this4.selectedRowKeys = [];
      }).catch(function (err) {
        console.log("debug log --> ", err);
      });
    }
  }
};